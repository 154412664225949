import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from "@angular/core";
import { ConverterService } from "../services/converter.service";
import { UntypedFormControl } from "@angular/forms";
import { Wrapper } from "../wrapper";
import { SubcontentService } from "../services/subcontent.service";
import { MappingElement } from "../types/mapping/MappingElement";

@Component({
  selector: "app-element",
  templateUrl: "./element.component.html",
  styleUrls: ["./element.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ElementComponent implements OnInit, AfterViewInit {
  @Input() representedMappingElement!: MappingElement;
  @Input() id!: number;
  @Input() exampledoc!: UntypedFormControl;
  @Input() wrapper!: Wrapper;
  @Input() urlAllDoctyps!: string;
  @Input() isLast!: boolean;
  @Input() mTitles!: string[] | null;
  @Input() kTitles!: string[] | null;

  @ViewChild("childrenContainer", { read: ViewContainerRef })
  childrenContainer!: ViewContainerRef;
  @ViewChild("content") content!: ElementRef<HTMLDivElement>;
  @ViewChild("subcontent") subcontent!: ElementRef<HTMLDivElement>;
  @ViewChild("pre") prefix!: ElementRef<HTMLSpanElement>;
  @ViewChild("post") postfix!: ElementRef<HTMLSpanElement>;
  @ViewChild("label") label!: ElementRef<HTMLSpanElement>;

  constructor(
    private converterService: ConverterService,
    private componentRef: ElementRef,
    private subcontentService: SubcontentService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.init();
  }

  public init() {
    const labelWrapDiv = this.label.nativeElement;
    const contentDiv = this.content.nativeElement;
    labelWrapDiv.id = "elcontent" + this.id;
    
    if (this.representedMappingElement.elabel) {
      this.representedMappingElement.elabel.forEach((label: any) => {
        let labelstring = label.labelstring;
        if (!labelstring) {
          labelstring = "";
        }
        const labelSpan = this.label.nativeElement;
        labelSpan.innerHTML = labelstring;
      });
    }

    contentDiv.classList.add("content-wrap");
    this.prePost();
    const subcontentDiv = this.subcontent.nativeElement;
    const econtents = this.representedMappingElement.econtent;

    const wrapper = new Wrapper(
      this.componentRef.nativeElement,
      this.wrapper,
      labelWrapDiv,
      contentDiv,
      subcontentDiv,
      econtents.length,
      this.representedMappingElement
    );

    econtents.forEach((subcontent: any, subcontentIndex: number) => {
      let exampleElement;
      if (subcontent.etype != "linkgroup") {
        if (subcontent.epart) {
          exampleElement = this.converterService.get(
            this.exampledoc,
            subcontent.epart
          );
        } else {
          exampleElement = this.exampledoc;
        }
      } else {
        exampleElement = this.exampledoc;
      }
      this.subcontentService.createSubComponent(this.childrenContainer, {
        representedMappingElement: subcontent,
        id: this.id + " " + subcontentIndex,
        exampleElement: exampleElement,
        wrapper: wrapper,
        isLastBlockElement: false,
        kTitles: this.kTitles,
        mTitles: this.mTitles,
        childCount: econtents?.length,
      });
    });
  }

  prePost() {
    const prefixSpan = this.prefix.nativeElement;
    const prefix = this.representedMappingElement.epartpre;
    if (prefix) {
      if (prefix.epartpre) {
        prefixSpan.innerHTML = prefix.epartpre;
        this.converterService.handleEpartPrePostClass(
          prefix.eclass,
          prefixSpan
        );
      }
    }
    const postfixSpan = this.postfix.nativeElement;
    const postfix = this.representedMappingElement.epartpost;
    if (postfix) {
      if (postfix.epartpost) {
        if (
          this.isLast &&
          postfix.eclass !== undefined &&
          postfix.eclass.includes("without-last")
        ) {
          // leave out epartpost
        } else {
          postfixSpan.innerHTML = postfix.epartpost;
        }
        this.converterService.handleEpartPrePostClass(
          postfix.eclass,
          postfixSpan
        );
      }
    }
  }
}
