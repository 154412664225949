import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QueryStateService } from 'src/app/services/query-state.service';
import { SEARCH_CONFIG } from 'src/search-config';

@Component({
  selector: 'app-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss'],
})
export class ChipListComponent {
  queryState: any; // Typ entsprechend anpassen

  constructor(private queryStateService: QueryStateService) {
    this.queryStateService.queryStateObservable.subscribe(state => {
      this.queryState = state;
    });
  }


  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }
  
  
  
  


  
  removeAggregation(aggKey: string, value: string) {
    this.queryStateService.removeAggregation(aggKey, value);
  }
}
