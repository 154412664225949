export const SEARCH_CONFIG = {
  index: "fud_n4mvh_doctyp5web",
  title: "NFDI4Memory Data Literacy Virtual Hub",
  logoImg: "/assets/img/Logo_for_memory_RGB.svg",
  debug: true,
  docTyps: [
    {
      docLabel: "Texte",
      docValue: "Text",
      docField: "facet_type",
      docIcon: "article",
    },
    {
      docLabel: "Bücher",
      docValue: "Buch",
      docField: "facet_type",
      docIcon: "menu_book",
    },
    {
      docLabel: "Videos",
      docValue: "Video",
      docField: "facet_type",
      docIcon: "videocam",
    },
    {
      docLabel: "Quiz",
      docValue: "Quiz",
      docField: "facet_type",
      docIcon: "quiz",
    },
    {
      docLabel: "Tutorials",
      docValue: "Tutorial",
      docField: "facet_type",
      docIcon: "history_edu",
    },
    {
      docLabel: "Lernplattformen",
      docValue: "Lernplattform",
      docField: "facet_type",
      docIcon: "school",
    },
    {
      docLabel: "Anderes",
      docValue: "Anderes",
      docField: "facet_type",
      docIcon: "more_horiz",
    },
  ],
  matTyps: [
    {
      matLabel: "Lehr-/Lernmaterial",
      matValue: "Lehr-/Lernmaterial",
      matField: "facet_mattyp",
      matIcon: "history_edu",
    },
    {
      matLabel: "Lehrmaterial",
      matValue: "Lehrmaterial",
      matField: "facet_mattyp",
      matIcon: "podium",
    },
    {
      matLabel: "Lernmaterial",
      matValue: "Lernmaterial",
      matField: "facet_mattyp",
      matIcon: "local_library",
    }
  ],
  specificFields: [
    {
      fieldName: "facet_author",
      uiTitle: "Autor*innen",
      facet: "facet_author",
    },
    {
      fieldName: "facet_topics",
      uiTitle: "Themen",
      facet: "facet_topics",
    },
    // {
    //   fieldName: "facet_language",
    //   uiTitle: "Sprache",
    //   facet: "facet_language",
    // },
  ],
  range: {
    gte: "sort_date",
    lte: "sort_date",
  },
  sort: [
    { sortName: "Relevanz", sortField: "", sortMethod: "" },
    {
      sortName: "Titel absteigend",
      sortField: "sort_title",
      sortMethod: "ASC",
    },
    {
      sortName: "Titel aufsteigend",
      sortField: "sort_title",
      sortMethod: "DESC",
    },
  ],
  aggs: [
    {
      facetField: "facet_author",
      facetLabel: "Autor*innen",
      facetIcon: "person",
      show: false
    },
    {
      facetField: "facet_discipline",
      facetLabel: "Fachdisziplin",
      facetIcon: "article",
      show: true
    },
    {
      facetField: "facet_mattyp",
      facetLabel: "Materialtyp",
      facetIcon: "history_edu",
      show: true
    },
    {
      facetField: "facet_level",
      facetLabel: "Kompetenzstufe",
      facetIcon: "stairs",
      show: true
    },
    {
      facetField: "facet_type",
      facetLabel: "Lernressourcentyp",
      facetIcon: "folder",
      show: true
    },
    {
      facetField: "facet_license",
      facetLabel: "Lizenz(en)",
      facetIcon: "gavel",
      show: true
    },
    {
      facetField: "facet_methods",
      facetLabel: "Methoden",
      facetIcon: "build",
      show: true
    },
    {
      facetField: "facet_keywords",
      facetLabel: "Schlagworte",
      facetIcon: "manage_search",
      show: true
    },
    {
      facetField: "facet_language",
      facetLabel: "Sprache",
      facetIcon: "language",
      show: true
    },
    {
      facetField: "facet_topics",
      facetLabel: "Themen",
      facetIcon: "account_balance",
      show: true
    },
    {
      facetField: "facet_tools",
      facetLabel: "Tools",
      facetIcon: "construction",
      show: true
    },
    {
      facetField: "facet_target",
      facetLabel: "Zielgruppe",
      facetIcon: "map",
      show: true
    },
  ],
};
