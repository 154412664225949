import {
  Component,
  ViewChild,
  AfterViewInit,
  Renderer2,
  ViewChildren,
  QueryList,
  ElementRef,
  ViewEncapsulation,
  Input,
  OnInit,
  ViewContainerRef,
} from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormControl,
  FormArray,
} from "@angular/forms";
import { registerLocaleData } from "@angular/common";
import localeDe from "@angular/common/locales/de";
import localeDeExtra from "@angular/common/locales/extra/de";
import { ElementComponent } from "../element/element.component";
import { ConverterService } from "../services/converter.service";
import { Wrapper } from "../wrapper";
import { HttpService } from "../services/http.service";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { HttpClient } from "@angular/common/http";
import {
  inputMethods,
  mappingNames,
  route,
  standardEnvironment,
} from "../config";
import { Mapping } from "../types/mapping/Mapping";

registerLocaleData(localeDe, "de-DE", localeDeExtra);

@Component({
  selector: "app-converter-view",
  templateUrl: "./converter-view.component.html",
  styleUrls: ["./converter-view.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ConverterViewComponent implements OnInit, AfterViewInit {
  // while calculating new views, this component gets hidden
  // can be accessed to handle the wrapping of the converter-view in other projects
  hidden: boolean = true;

  // Can be used to hand over the id of a document such that this converter creates a view.

  private angularComponentInputSubscription?: Subscription;
  // Korrekt definiertes Input-Property ohne Alias
  @Input() id$!: Observable<number | string>;

  @Input() mappingComponentType: string | undefined;

  // Can be used to hand over a specific mapping, if there is more than one mapping per doctype
  // If this argument is given, then the mappings must have the property 'mapping-component-type' and will be compared to this

  @ViewChild("wrap") wrap!: ElementRef<HTMLDivElement>;
  @ViewChild("subcontent") subcontent!: ElementRef<HTMLDivElement>;
  @ViewChild("heading") heading!: ElementRef<HTMLSpanElement>;
  @ViewChildren("childrenContainer", { read: ViewContainerRef })
  childrenContainer!: QueryList<ViewContainerRef>;

  private documentID!: string;
  private mappings: {
    mapping: Mapping;
    form: UntypedFormGroup;
    index: string;
  }[] = [];
  private index!: string;
  private mappingForm!: UntypedFormGroup;
  private mappingJSON!: Mapping;
  private exampledoc!: UntypedFormControl;
  private lastTryWasError = false;
  private lastID: string = "";

  private routeParamSubscription?: Subscription;
  private routeQueryParamSubscription?: Subscription;

  constructor(
    private httpService: HttpService,
    private httpClient: HttpClient,
    private converterService: ConverterService,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2
  ) {
    if (inputMethods.routeParameter) {
      this.routeParamSubscription = this.route.paramMap.subscribe(
        this.paramHandling
      );
    }
    if (inputMethods.routeQueryParameter) {
      this.routeQueryParamSubscription = this.route.queryParamMap.subscribe(
        this.paramHandling
      );
    }
    mappingNames.forEach((name) => {
      console.log("Trying to find mapping ", name);
      this.httpClient
        .get<any>(standardEnvironment.serverUrl + "/assets/mappings/" + name)
        .subscribe((data) => {
          // Use the retrieved data here
          console.log("Trying to add mapping ", name, " with value ", data);
          setTimeout(() => {
            this.addMapping(data);
          });
        });
    });
  }

  private paramHandling = (params: ParamMap) => {
    const id = params.get("id");
    if (id && id !== this.documentID) {
      this.handleID(id);
    }
  };

  private handleID(id: string) {
    this.hidden = true;
    this.changeToDocument(id);
    setTimeout(async () => {
      if (this.mappings.length <= 0) {
        return;
      }
      const found = await this.changeMapping();
      console.log("Found these mappings: ", this.mappings);
      if (found) {
        this.update();
      } else {
        if (this.lastID) {
          this.router.navigate([
            standardEnvironment.serverUrl + route + "/" + this.lastID,
          ]);
        }
      }
    }, 500);
  }

  ngOnInit(): void {
    if (inputMethods.angularComponentInput && this.id$) {
      console.log("Received id$: ", this.id$);
      this.angularComponentInputSubscription = this.id$.subscribe(
        (id: number | string) => {
          console.log("Handling ID: ", id);
          this.handleID(id.toString());
        }
      );
    }
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    if (this.routeParamSubscription) {
      this.routeParamSubscription.unsubscribe();
    }
    if (this.routeQueryParamSubscription) {
      this.routeQueryParamSubscription.unsubscribe();
    }
    if (this.angularComponentInputSubscription) {
      this.angularComponentInputSubscription.unsubscribe();
    }
  }

  // add only one mapping for each doctype!
  // returns success
  public async addMapping(mappingJSON: Mapping): Promise<boolean> {
    console.log("Adding mapping ", mappingJSON);
    const mappingForm = this.converterService.formFromJSON(mappingJSON);
    const index = this.converterService.tryGetActiveIndexFromFields(mappingForm);
    const componentType = mappingJSON["mapping-component-type"];
    console.log("Checking mapping - Index:", index, "Component Type:", componentType);
    
    // Only add this mapping if it matches our required component type
    if (this.mappingComponentType && componentType !== this.mappingComponentType) {
      console.log("Skipping mapping - wrong component type. Expected:", this.mappingComponentType, "Got:", componentType);
      return false;
    }

    // Check if we already have a mapping for this index and component type
    const existingMapping = this.mappings.find(
      (element) => element.index === index && 
                   element.mapping["mapping-component-type"] === componentType
    );
    
    if (existingMapping) {
      console.warn(
        `Tried to add multiple mappings for the same index (${index}) and component type (${componentType}). Storing only first...`
      );
      return false;
    }

    console.log("Adding new mapping for index:", index, "component type:", componentType);
    this.mappings.push({ mapping: mappingJSON, form: mappingForm, index });
    this.mappingForm = mappingForm;
    return true;
  }

  private changeToDocument(docID: string) {
    if (this.documentID) {
      this.lastID = this.documentID;
    }
    this.documentID = docID;
  }

  private async changeMapping(): Promise<boolean> {
    let found = false;
    const url = await this.converterService.getURL(this.mappingForm);
    console.log("Looking for document:", this.documentID, "URL:", url);
    
    await this.httpService
      .getDocument(url, this.documentID)
      .then((document: any) => {
        console.log("Found document:", document);
        const index = document._index;
        
        // Find mapping that matches both index and component type
        const matchingMapping = this.mappings.find(mapping => 
          mapping.index === index && 
          mapping.mapping["mapping-component-type"] === this.mappingComponentType
        );

        if (matchingMapping) {
          console.log("Found matching mapping:", matchingMapping.mapping);
          this.mappingJSON = matchingMapping.mapping;
          this.mappingForm = matchingMapping.form;
          this.index = index;
          found = true;
        } else {
          console.warn(
            `No mapping found for index ${index} and component type ${this.mappingComponentType}`
          );
        }
      });

    if (!found) {
      console.warn(
        "No suitable mapping for document with id " +
          this.documentID +
          " has been added!"
      );
    }
    return found;
  }

  public update() {
    this.hidden = true;
    console.log(
      "Trying to find document with ID " +
        this.documentID +
        " on index " +
        this.index
    );
    this.httpService.getDocument(this.index, this.documentID).then(
      (doc: any) => {
        this.updateForGivenDocument(doc);
        this.lastTryWasError = false;
      },
      (reject: any) => {
        if (reject?.error === "Not Found") {
          // try using the last id, it can be that the fud index is not correct with the id -> internal error
          if (!this.lastTryWasError) {
            this.router.navigate([
              standardEnvironment.serverUrl + route + "/" + this.lastID,
            ]);
          }
          this.lastTryWasError = true;
        } else {
          console.error(reject);
        }
      }
    );
  }

  /*
   use this if document is already given, because it's faster, http-request is not done twice
  */
  public async updateForGivenDocument(doc: any) {
    console.log("Updating view for document ", doc);
    this.hidden = true;
    this.childrenContainer.forEach((container) => container.clear());
    // Beispiel-Dokument neu anfragen
    this.exampledoc = doc._source as UntypedFormControl;
    const elements = this.mappingForm.controls["elements"] as FormArray;
    const mainWrapper = new Wrapper(
      this.wrap.nativeElement,
      null,
      this.heading.nativeElement,
      this.subcontent.nativeElement,
      this.subcontent.nativeElement,
      elements.value.length,
      elements
    );
    const headingElementIndex = await this.handleHeading(elements, mainWrapper);
    elements.value.forEach(
      async (mappingElement: any, elementIndex: number) => {
        if (elementIndex !== headingElementIndex) {
          const elRef = this.childrenContainer
            .get(1)!
            .createComponent(ElementComponent);
          const instance = elRef.instance;
          instance.representedMappingElement = mappingElement;
          instance.id = elementIndex;
          instance.exampledoc = this.exampledoc;
          instance.wrapper = mainWrapper;
          instance.urlAllDoctyps = await this.converterService.getURL(
            this.mappingForm
          );
          instance.kTitles = this.getTitles("k-title");
          instance.mTitles = this.getTitles("m-title");
          this.renderer.addClass(elRef.location.nativeElement, "row");
          this.renderer.setAttribute(
            elRef.location.nativeElement,
            "width",
            "100%"
          );
        }
      }
    );
    mainWrapper.onReady().then(async () => {
      // If the wrapper contains no values from fud then the whole html construct around the wrapper will be deleted right here.
      await mainWrapper.deleteIfEmpty();
      setTimeout(() => {
        this.hidden = false;
      }, 100);
    });
  }

  /*
    initiates the heading and returns if a mapping element has been used for this purpose (index) or the mapping title (null)
  */
  private async handleHeading(
    elements: FormArray,
    mainWrapper: Wrapper
  ): Promise<number | null> {
    let headingElementIndex: number | null = null;
    elements.value.forEach(async (elementValue: any, index: number) => {
      if (elementValue?.eclass?.includes("heading")) {
        headingElementIndex = index;
        const element = this.childrenContainer
          .get(0)!
          .createComponent(ElementComponent);
        const instance = element.instance;
        instance.representedMappingElement = elementValue;
        instance.id = index;
        instance.exampledoc = this.exampledoc;
        instance.wrapper = mainWrapper;
        instance.urlAllDoctyps = await this.converterService.getURL(
          this.mappingForm
        );
        instance.kTitles = this.getTitles("k-title");
        instance.mTitles = this.getTitles("m-title");
      }
    });
    const htmlHeading = this.heading.nativeElement;
    if (headingElementIndex === null) {
      // alternative : use mappingtitle as heading
      htmlHeading.innerHTML = "";
      // htmlHeading.innerHTML = this.mappingForm.controls["mappingtitle"]?.value;
    }
    return headingElementIndex;
  }

  // field should be 'k-title' or 'm-title'
  private getTitles(field: string): string[] | null {
    const form = this.mappingForm.controls[field];
    if (form) {
      const titles: string[] = [];
      const fieldName = form.value;
      for (const [k, v] of Object.entries(this.exampledoc)) {
        if (k.includes(fieldName)) {
          titles.push(v);
        }
      }
      return titles;
    } else {
      return null;
    }
  }
}
