<span #label class=""> </span>
<ng-container #outerContainer [ngTemplateOutlet]="outerDefault"></ng-container>

<ng-template #outerDefault>
  <span #content>
    <ng-container
      #innerContainer
      [ngTemplateOutlet]="innerDefault"
    ></ng-container>
  </span>
</ng-template>

<ng-template #outerLink>
  <a #content>
    <ng-container
      #innerContainer
      [ngTemplateOutlet]="innerDefault"
    ></ng-container>
  </a>
</ng-template>

<ng-template #outerListItem>
  <li #content>
    <ng-container
      #innerContainer
      [ngTemplateOutlet]="innerDefault"
    ></ng-container>
  </li>
</ng-template>

<ng-template #innerDefault>
  <span #pre> </span>
  <span #subcontent>
    <ng-container #childrenContainer></ng-container>
  </span>
  <span #post> </span>
</ng-template>

<ng-template #innerUnorderedList>
  <span #pre> </span>
  <ul #subcontent>
    <ng-container #childrenContainer></ng-container>
  </ul>
  <span #post> </span>
</ng-template>

<ng-template #innerOrderedList>
  <span #pre> </span>
  <ol #subcontent>
    <ng-container #childrenContainer></ng-container>
  </ol>
  <span #post> </span>
</ng-template>
