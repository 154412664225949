import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { QueryService } from "../../services/query.service";
import { ApiService } from "../../services/api.service";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatPaginatorIntl } from "@angular/material/paginator"; // Import MatPaginatorIntl
import { SEARCH_CONFIG } from "src/search-config";
import { Router } from "@angular/router";
import { QueryStateService } from "src/app/services/query-state.service";
import { BookmarkService } from "src/app/services/bookmark.service";
import { BookmarkItem } from "src/app/services/bookmark.service";
import {
  trigger,
  state,
  style,
  transition,
  animate,
  query,
  stagger,
} from "@angular/animations";
import { MatSnackBar } from "@angular/material/snack-bar";
// import { GermanMatPaginatorIntlService } from "src/app/german-paginator-intl";

@Component({
  selector: "app-list-item",
  templateUrl: "./list-item.component.html",
  styleUrls: ["./list-item.component.scss"],
  animations: [
    trigger("listFadeIn", [
      transition("* <=> *", [
        // This transition applies to any change in the list
        query(
          ":enter",
          [
            style({ opacity: 0 }),
            stagger("100ms", [
              // Adjust the delay here as needed
              animate("0.5s", style({ opacity: 1 })), // Adjust animation duration here
            ]),
          ],
          { optional: true }
        ), // Marking the query as optional: true is useful for server-side rendering
      ]),
    ]),
  ],
})
export class ListItemComponent {
  searchResults: any;
  esQuery: any;
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  totalDocuments = 0;
  pageSize = 25;
  pageIndex = 0;
  snr: any;
  expanded: boolean | undefined;

  constructor(
    private queryService: QueryService,
    private router: Router,
    public queryStateService: QueryStateService,
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    private bookmarkService: BookmarkService,
    private paginatorIntl: MatPaginatorIntl // Inject the MatPaginatorIntl service
  ) { }

  ngOnInit() {
    // Translate paginator labels
    this.paginatorIntl.itemsPerPageLabel = "Einträge pro Seite:";
    this.paginatorIntl.nextPageLabel = "Nächste Seite";
    this.paginatorIntl.previousPageLabel = "Vorherige Seite";
    this.paginatorIntl.firstPageLabel = "Erste Seite";
    this.paginatorIntl.lastPageLabel = "Letzte Seite";
    this.paginatorIntl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      if (length === 0 || pageSize === 0) {
        return `0 von ${length}`;
      }
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} von ${length}`;
    };
    this.queryService.response$.subscribe((data) => {
      this.searchResults = data;
      this.totalDocuments = data?.hits?.total?.value; // Adjust according to your API response structure
      // Process data as needed
      this.searchResults?.hits?.hits.forEach((document: any) => {
        // CUSTOM CHANGES HERE!!!!!!
        // if (document["_source"]["2_ar_kplink"]) {
        //   this.apiService
        //     .getDocument(document["_source"]["2_ar_kplink"])
        //     .subscribe((res: any) => {
        //       if (res.hits.hits[0]["_source"]["4_pr_snr"]) {
        //         document["_source"]["4_pr_snr"] =
        //           res.hits.hits[0]["_source"]["4_pr_snr"];
        //       }
        //     });
        // }
      });
    });

    this.queryService.getQuery().subscribe((query) => {
      this.esQuery = query;
    });
  }

  ngAfterViewInit(): void {
    this.expanded = false;
  }

  onPageChange(event: PageEvent) {
    this.queryStateService.updatePageSize(event.pageSize);
    this.queryStateService.updatePageIndex(event.pageIndex);
  }
  // Funktion, um die Konfiguration basierend auf aggKey zu finden
  getDoctypConfig(key: string) {
    const config = SEARCH_CONFIG.docTyps.find(
      (docTyp) => docTyp.docValue === key
    );
    return config || { docLabel: key, docIcon: "" };
  }

  getMattypConfig(key: string) {
    const config = SEARCH_CONFIG.matTyps.find(
      (matTyp) => matTyp.matValue === key
    );
    return config || { matLabel: key, matIcon: "" };
  }


  toDocument(id: any) {
    this.router.navigate(["/document", id]);
  }

  toggleBookmark(event: Event, document: any): void {
    event.stopPropagation();
    console.log('Toggling bookmark for document:', document);
    
    if (!document?._id || !document?._source?.["5_dcterms_title"]) {
      console.error('Missing required document data:', document);
      this.snackBar.open("Fehler: Dokument-ID oder Titel fehlt", "OK", {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
      return;
    }

    const bookmarkItem: BookmarkItem = {
      id: document._id,
      title: document._source["5_dcterms_title"],
    };

    // Toggle the bookmark in the service
    this.bookmarkService.toggleItem(bookmarkItem.id, bookmarkItem.title);

    // Determine the new state after toggling
    const isNowBookmarked = this.bookmarkService.isInMerkliste(bookmarkItem.id);

    // Show feedback to the user
    const message = isNowBookmarked
      ? "Zur Merkliste hinzugefügt"
      : "Von Merkliste entfernt";
      
    this.snackBar.open(message, "", {
      duration: 2000,
      panelClass: isNowBookmarked ? ['success-snackbar'] : ['info-snackbar']
    });
  }

  isBookmarked(document: any): boolean {
    if (!document?._id) {
      console.warn('Cannot check bookmark status: document ID is missing', document);
      return false;
    }
    return this.bookmarkService.isInMerkliste(document._id);
  }
}
