import { SEARCH_CONFIG } from "src/search-config";

export const environment = {
  production: false,
  elasticPort: "http://localhost:9210/",
  documentUrl: `${window.location.origin}/assets/api/elastic-document.php`,
  searchUrl: `${window.location.origin}/assets/api/elastic-search.php`,
  analyseUrl: `${window.location.origin}/assets/api/elastic-analyse.php`,
  serverUrl: `${window.location.origin}/`,
  wordpressPageUrl: `${window.location.origin}/`,
  index: SEARCH_CONFIG.index,
  getIndices: `${window.location.origin}/assets/api/elastic-getIndices.php`,
};
