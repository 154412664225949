import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
} from "@angular/core";
import { SubcontentComponent } from "../base/subcontent.component";
import { LinkService } from "../../services/link.service";
import { ConverterService } from "../../services/converter.service";
import { DateService } from "../../services/date.service";
import { SubcontentService } from "../../services/subcontent.service";
import { MappingSubcontent } from "../../types/mapping/subcontent/MappingSubcontent";
import { MappingSubcontentBlock } from "../../types/mapping/subcontent/extends/MappingSubcontentBlock";

@Component({
  selector: "app-subcontent--block",
  templateUrl: "../base/subcontent.component.html",
  styleUrls: ["./block.subcontent.component.scss"],
})
export class BlockSubcontentComponent extends SubcontentComponent {
  @Input() override representedMappingElement!: MappingSubcontentBlock;

  constructor(
    protected override linkService: LinkService,
    protected override converterService: ConverterService,
    protected override dateService: DateService,
    protected override componentRef: ElementRef,
    protected override subcontentService: SubcontentService,
    // protected override zone: NgZone,
    protected override cdr: ChangeDetectorRef
  ) {
    super(
      converterService,
      dateService,
      componentRef,
      linkService,
      subcontentService,
      // zone,
      cdr
    );
  }

  override init() {
    switch (this.representedMappingElement.typ) {
      default:
        switch (this.representedMappingElement.etype) {
          case "block":
            this.handleBlock();
            break;
          default:
            console.error(
              "Created BlockSubcontentComponent but is not of etype block!"
            );
            // in case something unexpected happens, let the super class handle it
            super.init();
        }
    }
  }

  handleBlock() {
    const mappingSubcontents = this.representedMappingElement.subcontent;
    const amountOfChildren = mappingSubcontents.length;
    const wrapper = this.handleBoxing(amountOfChildren);

    // wählt nächsten FUD-Eintrag aus dem Beispiel-Dokument. Falls epart leer, siehe nächste for-Schleife
    if (this.representedMappingElement.fudfeldtyp === "y") {
      let counter = 0;
      this.exampleElement?.forEach((value: string) => {
        mappingSubcontents.forEach(
          (mappingSubcontent: MappingSubcontent, subcontentIndex: number) => {
            if (!this.exampleElement) {
              for (let i = 0; i < amountOfChildren; i++) {
                this.createSubComponent({
                  representedMappingElement: mappingSubcontent,
                  id: this.id + " " + subcontentIndex,
                  exampleElement: value,
                  wrapper: wrapper,
                  childCount: this.exampleElement.length,
                  isLastBlockElement: ++counter == this.exampleElement.length,
                  kTitles: this.kTitles,
                  mTitles: this.mTitles,
                });
              }
            }
          }
        );
      });
    }
    if (this.exampleElement) {
      const entries = Object.entries(this.exampleElement);
      let counter = 0;
      let isLastBlockElement: boolean;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [k, v] of entries) {
        isLastBlockElement = ++counter == entries.length;
        mappingSubcontents.forEach(
          (mappingSubcontent: MappingSubcontent, subcontentIndex: number) => {
            this.createNextSubcontentComponent(
              v,
              mappingSubcontent,
              subcontentIndex,
              wrapper,
              amountOfChildren,
              isLastBlockElement
            );
            // make mapping children ready even when no fud representation exists.
            setTimeout(() => this.wrapper.oneChildMoreReady());
          }
        );
      }
      if (entries.length === 0) {
        for (let i = 0; i < amountOfChildren; i++) {
          this.wrapper.oneChildMoreReady(); // make mapping children ready even when no fud representation exists.
        }
      }
    }
    if (!this.exampleElement) {
      for (let i = 0; i < amountOfChildren; i++) {
        this.wrapper.oneChildMoreReady(); // make mapping children ready even when no fud representation exists.
      }
    }
  }
}
