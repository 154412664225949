import { NgModule } from "@angular/core";
import { ConverterViewComponent } from "./converter-view/converter-view.component";
import { CommonModule, NgTemplateOutlet } from "@angular/common";
import { AppRoutingModule } from "../../app-routing.module";
import { ElementComponent } from "./element/element.component";
import { SubcontentComponent } from "./subcontent/base/subcontent.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { BlockSubcontentComponent } from "./subcontent/extends/block.subcontent.component";
import { ChildSubcontentComponent } from "./subcontent/extends/child.subcontent.component";
import { FileSubcontentComponent } from "./subcontent/extends/file.subcontent.component";
import { GroupSubcontentComponent } from "./subcontent/extends/group.subcontent.component";
import { HtmltextSubcontentComponent } from "./subcontent/extends/htmltext.subcontent.component";
import { JsonSubcontentComponent } from "./subcontent/extends/json.subcontent.component";
import { LinkItemSubcontentComponent } from "./subcontent/extends/link-item.subcontent.component";
import { StringSubcontentComponent } from "./subcontent/extends/string.subcontent.component";
import { LinkSubcontentComponent } from "./subcontent/extends/link.subcontent.component";

@NgModule({
  declarations: [
    ConverterViewComponent,
    ElementComponent,
    SubcontentComponent,
    BlockSubcontentComponent,
    ChildSubcontentComponent,
    FileSubcontentComponent,
    GroupSubcontentComponent,
    HtmltextSubcontentComponent,
    JsonSubcontentComponent,
    LinkSubcontentComponent,
    LinkItemSubcontentComponent,
    StringSubcontentComponent,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    MatProgressSpinnerModule,
    NgTemplateOutlet,
  ],
  exports: [ConverterViewComponent, AppRoutingModule],
  providers: [],
})
export class ConverterModule {}
