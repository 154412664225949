@if (queryStateService.isLoading$ | async) {
<mat-progress-bar
  class="position-absolute"
  mode="indeterminate"
></mat-progress-bar>
}

<mat-paginator
  [length]="totalDocuments"
  [pageSize]="pageSize"
  [pageSizeOptions]="[5, 10, 25, 100]"
  (page)="onPageChange($event)"
  aria-label="Select page"
>
</mat-paginator>

@if (!(queryStateService.isLoading$ | async)) {
<div class="container-fluid scroll">
  <div class="row">
    <div class="col mb-5 pb-3" @listFadeIn>
      @if (searchResults?.hits?.total?.value > 0) { @for (document of
      searchResults?.hits?.hits; track document) {
      <div
        class="flex flex-col md:flex-row gap-4 mb-4 p-4 border border-gray-200 rounded-lg bg-white"
      >
        <!-- Thumbnail -->
        @if (document['_source']['5_sourcename0']) {
        <a
          href="/#/document/{{ document['_id'] }}"
          (click)="toDocument(document['_id'])"
          class="text-sm font-semibold leading-6 text-primary-600 hover:text-primary-500"
        >
          <img
            src="/assets/vmimg/thb/thb_{{
              document['_source']['5_sourcename0']
            }}"
            alt="Screenshot-Thumbnail"
          />
        </a>
        }

        <!-- Content -->
        <div class="flex-1 min-w-0">
          <div class="content">
            <!-- Title -->
            <h2>
              <a
                href="/#/document/{{ document['_id'] }}"
                (click)="toDocument(document['_id'])"
                class="text-lg text-primary-500 hover:text-primary-600"
                >{{ document["_source"]["5_dcterms_title"] }}</a
              >
            </h2>

            <!-- Meta Information -->
            <table>
              @if (document['_source']['5_mo_haslearningtype']) {
              <tr>
                <td>Lernressourcentyp(en)</td>
                <td>
                  @for (typ of document['_source']['5_mo_haslearningtype'];
                  track typ; let isLast = $last) {
                  <span>{{ typ.content }}{{ isLast ? "" : "; " }}</span>
                  }
                </td>
              </tr>
              } @if (document['_source']['5_dcterms_issued']) {
              <tr>
                <td>Publikationsdatum</td>
                <td>
                  {{
                    document["_source"]["5_dcterms_issued"]
                      | date : "dd.MM.yyyy"
                  }}
                </td>
              </tr>
              } @if (document['_source']['5_fabio_hasdiscipline']) {
              <tr>
                <td>Fachdisziplin(en)</td>
                <td>
                  @for (discipline of
                  document['_source']['5_fabio_hasdiscipline']; track
                  discipline; let isLast = $last) {
                  <span>{{ discipline.content }}{{ isLast ? "" : "; " }}</span>
                  }
                </td>
              </tr>
              }
            </table>
          </div>
        </div>

        <!-- Actions -->
        <div
          class="flex md:flex-col justify-end md:justify-between md:h-[150px] py-2 gap-2 no-print"
        >
          <button
            mat-icon-button
            class="icon-button custom-icon-button"
            aria-label="Mattyp"
          >
            <mat-icon>
              {{ getMattypConfig(document["_source"]["facet_mattyp"]).matIcon }}
            </mat-icon>
          </button>
          <button
            mat-icon-button
            class="icon-button"
            [class.text-primary-500]="isBookmarked(document)"
            [matTooltip]="
              isBookmarked(document)
                ? 'Von Merkliste entfernen'
                : 'Zur Merkliste hinzufügen'
            "
            aria-label="Bookmark"
            (click)="toggleBookmark($event, document)"
          >
            <mat-icon>
              {{ isBookmarked(document) ? "bookmark_added" : "bookmark_add" }}
            </mat-icon>
          </button>
        </div>
      </div>
      } } @else {
      <div class="text-center py-8">
        <p class="text-gray-600">Keine Ergebnisse gefunden</p>
      </div>
      }
    </div>
  </div>
</div>
}
