<div class="row no-gutters">
  <div class="col-md-8 bg-gray-50 p-5">
    @if (document && document['_source'] && document['_source']['2_html']) {
      <div
        class="scroll-me"
        innerHtml="{{ document['_source']['2_html'] }}"
      ></div>
    }

    @if (document && document['_source'] && document['_source']['3_html']) {
      <div
        class="scroll-me"
        innerHtml="{{ document['_source']['3_html'] }}"
      ></div>
    }

    @if (document && document['_source'] && document['_source']['4_html']) {
      <div
        class="scroll-me"
        innerHtml="{{ document['_source']['4_html'] }}"
      ></div>
    }
  </div>

  <div class="col-md-4">
    <mat-tab-group>
      <mat-tab label="Anmerkungen">
        <div class="m-2">
          @for (noteItem of sachKommentar; track noteItem; let i = $index) {
            <a
              (click)="
                highlightNotes($event, noteItem, 'notice-', 'highlight-sachkom')
              "
              class="linked-keyword mb-3"
              id="notice-{{ noteItem.ID }}"
              ><sup>{{ i + 1 }}</sup>
              {{ stripTags(noteItem.content) }}
            </a>
          }

          @if (sachKommentar.length <= 0) {
            Keine Anmerkungen vorhanden.
          }
        </div>
      </mat-tab>
      <mat-tab label="Annotation">
        @if (
          !(document && document['_source']) &&
          (document['_source']['index_sachindex_6'] ||
          document['_source']['index_raumindex_7'] ||
          document['_source']['index_personenindex_8'])
          ) {
          Keine Annotation vorhanden.
        }

        @if (
          document &&
          document['_source'] &&
          (document['_source']['index_sachindex_6'] ||
          document['_source']['index_raumindex_7'] ||
          document['_source']['index_personenindex_8'])
          ) {
          <mat-card class="mt-4 scroll-me blue-box">
            @if (
              document &&
              document['_source'] &&
              document['_source']['index_sachindex_6']
              ) {
              <span class="circle-sach"></span>
              <h4 class="my-0 ml-4">Sachindex</h4>
              <br />
              <mat-chip-listbox multiple="true">
                @for (
                  contentItem of document['_source']['index_sachindex_6']; track
                  contentItem; let index = $index) {
                  <mat-chip
                  (click)="
                    highlightNotes(
                      $event,
                      contentItem,
                      'index-',
                      'highlight-index'
                    )
                  "
                    [highlighted]="contentItem.selected"
                    class="linked-keyword"
                    >
                    {{ stripTags(contentItem.content) }}
                  </mat-chip>
                }
              </mat-chip-listbox>
            }
            @if (
              document &&
              document['_source'] &&
              document['_source']['index_personenindex_8']
              ) {
              <span class="circle-sach"></span>
              <h4 class="my-0 ml-4">Personenindex</h4>
              <br />
              <mat-chip-listbox multiple="true">
                @for (
                  contentItem of document['_source'][
                  'index_personenindex_8'
                  ]; track
                  contentItem; let index = $index) {
                  <mat-chip
                  (click)="
                    highlightNotes(
                      $event,
                      contentItem,
                      'index-',
                      'highlight-index'
                    )
                  "
                    [highlighted]="contentItem.selected"
                    class="linked-keyword"
                    >
                    {{ stripTags(contentItem.content) }}
                  </mat-chip>
                }
              </mat-chip-listbox>
            }
            @if (
              document &&
              document['_source'] &&
              document['_source']['index_raumindex_7']
              ) {
              <span class="circle-raum"></span>
              <h4 class="my-0 ml-4">Ortsindex</h4>
              <br />
              <mat-chip-listbox multiple="true">
                @for (
                  placeItem of document['_source']['index_raumindex_7']; track
                  placeItem; let index = $index) {
                  <mat-chip
                  (click)="
                    highlightNotes(
                      $event,
                      placeItem,
                      'index-',
                      'highlight-raum'
                    )
                  "
                    [highlighted]="placeItem.selected"
                    class="linked-keyword"
                    >
                    {{ stripTags(placeItem.content) }}
                  </mat-chip>
                }
              </mat-chip-listbox>
            }
            @if (
              document &&
              document['_source'] &&
              document['_source']['index_inhaltsverzeichnis_59']
              ) {
              <span class="circle-text"></span>
              <h4 class="my-0 ml-4">Inhaltsverzeichnis</h4>
              <br />
              <mat-chip-listbox multiple="true">
                @for (
                  textItem of document['_source'][
                  'index_inhaltsverzeichnis_59'
                  ]; track
                  textItem; let index = $index) {
                  <mat-chip
                    (click)="
                      highlightNotes(
                        $event,
                        textItem,
                        'index-',
                        'highlight-text'
                      )
                    "
                    [highlighted]="textItem.selected"
                    class="linked-keyword"
                    >
                    {{ stripTags(textItem.content) }}
                  </mat-chip>
                  <!-- <ng-container *ngIf="textItem.LmAdd.lmpgnd">
                  <a [href]="textItem.LmAdd.lmpgnd"
                  target="_blank">GND</a>
                </ng-container> -->
              }
            </mat-chip-listbox>
          }
        </mat-card>
      }
    </mat-tab>
  </mat-tab-group>
</div>
</div>
