import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
} from "@angular/core";
import { SubcontentComponent } from "../base/subcontent.component";
import { LinkService } from "../../services/link.service";
import { ConverterService } from "../../services/converter.service";
import { DateService } from "../../services/date.service";
import { SubcontentService } from "../../services/subcontent.service";
import { MappingSubcontentJson } from "../../types/mapping/subcontent/extends/MappingSubcontentJson";
import { UsefunctionSubcontent } from "../../types/mapping/subcontent/UsefunctionSubcontent";

@Component({
  selector: "app-subcontent--json",
  templateUrl: "../base/subcontent.component.html",
  styleUrls: ["./json.subcontent.component.scss"],
})
export class JsonSubcontentComponent extends SubcontentComponent {
  @Input() override representedMappingElement!: MappingSubcontentJson;

  constructor(
    protected override linkService: LinkService,
    protected override converterService: ConverterService,
    protected override dateService: DateService,
    protected override componentRef: ElementRef,
    protected override subcontentService: SubcontentService,
    // protected override zone: NgZone,
    protected override cdr: ChangeDetectorRef
  ) {
    super(
      converterService,
      dateService,
      componentRef,
      linkService,
      subcontentService,
      // zone,
      cdr
    );
  }

  override init() {
    switch (this.representedMappingElement.typ) {
      default:
        switch (this.representedMappingElement.etype) {
          case "json":
            this.handleJSON();
            break;
          default:
            console.error(
              "Created JsonSubcontentComponent but is not of etype json!"
            );
            // in case something unexpected happens, let the super class handle it
            super.init();
        }
    }
  }

  handleJSON() {
    const wrapper = this.handleBoxing(0);
    const content = this.exampleElement;
    if (!content) return;
    let str: string = "";
    if (this.exampleElement instanceof Object) {
      console.log(this.exampleElement);
    }
    if (this.exampleElement != undefined) {
      str += content;
    } else {
      str += "";
    }
    if (
      this.representedMappingElement.usefunction === "dateformat" &&
      this.representedMappingElement.subcontent
    ) {
      const usefunctionSubcontent = this.representedMappingElement
        .subcontent[0] as UsefunctionSubcontent;
      if (usefunctionSubcontent) {
        str = this.dateService.formatDate(
          str,
          usefunctionSubcontent.epart,
          usefunctionSubcontent.elang || "de-DE"
        );
      }
    } else if (
      this.representedMappingElement.usefunction === "replaceboolean" &&
      this.representedMappingElement.subcontent
    ) {
      if (this.exampleElement) {
        // replace true
        this.exampleElement =
          this.representedMappingElement.subcontent[0].epart;
      } else {
        // replace false
        this.exampleElement =
          this.representedMappingElement.subcontent[1].epart;
      }
    }
    this.subcontent.nativeElement.innerHTML = str;
    this.subcontent.nativeElement.classList.add("json-span");
    this.styleSpan(this.subcontent.nativeElement, wrapper);
  }
}
