import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../services/api.service";
import { MatDialog } from "@angular/material/dialog";
import { BookmarkService } from "../services/bookmark.service";
import { QueryService } from "../services/query.service";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { BookmarkItem } from "src/app/services/bookmark.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { BookmarkDialogComponent } from "../dialogs/bookmark-dialog/bookmark-dialog.component";
import { DatePipe } from "@angular/common";
import { SEARCH_CONFIG } from "src/search-config";

@Component({
  selector: "app-document-view",
  templateUrl: "./document-view.component.html",
  styleUrls: ["./document-view.component.scss"],
  providers: [DatePipe],
})
export class DocumentViewComponent implements OnInit, OnDestroy {
  document: any;
  documentIds: string[] = [];
  currentIndex: number = 0;
  private idsSubscription: Subscription | undefined;
  id$ = new BehaviorSubject<string>(""); // Falls nur string benötigt wird
  formattedHtmlContent: SafeHtml | undefined;
  imageUrl: any;
  imageQuelle: any;
  sortedEvents: any;
  fudLink: any;
  relationships: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    public bookmarkService: BookmarkService,
    private snackBar: MatSnackBar,
    private router: Router,
    public queryService: QueryService,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private api: ApiService,
    private datePipe: DatePipe // Fügen Sie diese Zeile hinzu
  ) { }

  ngOnInit() {
    this.idsSubscription = this.queryService.ids$.subscribe((ids) => {
      this.documentIds = ids;
      this.route.paramMap.subscribe((params) => {
        const id = params.get("id");
        if (id) {
          this.currentIndex = this.documentIds.indexOf(id);
          this.fetchData(id);

          this.id$.next(String(id)); // Aktualisieren des Observables mit der neuen ID
          console.log("Emitted id$: ", id);
        }
      });
    });
  }

  ngOnDestroy() {
    if (this.idsSubscription) {
      this.idsSubscription.unsubscribe();
    }
  }

  getDoctypConfig(key: string) {
    const config = SEARCH_CONFIG.docTyps.find(
      (docTyp) => docTyp.docValue === key
    );
    return config || { docLabel: key, docIcon: "" };
  }

  fetchData(id: any) {
    // Reset image data
    this.imageUrl = "";
    this.imageQuelle = "";

    this.apiService.getDocument(id).subscribe(
      (res: any) => {
        this.document = res.hits.hits[0];

        if (this.document && this.document._source) {
          // this.processDocumentData();
          // this.setFormattedHtmlContent(); // HTML-Inhalt jedes Mal neu setzen
          // this.processRelationships(this.document._source["4_Beziehungen"]);
          // this.initDialogLinks(); // Reinitialize the links for modal dialogs
          this.cdr.detectChanges(); // Manuelle Änderungserkennung nach dem Update
          this.document._source["5_Nachweis"].forEach((element: any) => {
            this.api.getDocument(element.dcterms_relation).subscribe((res2: any) => {
              element.relTitle = res2.hits.hits[0]["_source"]["display_title"];
              // console.log("hiersuchen " + res2 + " " + res2["5_dcterms_title"])
            })
          });
          // console.log("hierauchsuchen" + JSON.stringify(this.document._source))
          // this.document._source["5_Nachweis"].forEach((element: any) => {
          //   this.api.getDocument(element.dcterms_relation).subscribe(
          //     (res2: any) => {
          //       // Prüfe, ob die Struktur korrekt ist
          //       console.log("Dokument: ", JSON.stringify(res2.hits.hits[0]["_source"].display_title));
          //       const title = res2?.hits?.hits[0]?.["5_dcterms_title"];
          //       if (title) {
          //         element.relTitle = title; // Wert zuweisen
          //         console.log(`Titel gefunden: ${title}`);
          //       } else {
          //         console.error("Titel nicht gefunden oder fehlerhafte Struktur:", res2);
          //       }
          //     },
          //     (error) => {
          //       console.error("Fehler bei der API-Abfrage:", error);
          //     }
          //   );
          // });

          // Verzögertes Logging für asynchrone Operationen
          //     setTimeout(() => {
          //       console.log("Aktualisiertes Dokument:", JSON.stringify(this.document._source));
          //     }, 1000);
        }
      },
      (error) => {
        console.error("Error fetching data:", error);
      }
    );
  }

  // Verarbeitung der Dokumentdaten
  processDocumentData() { }

  // Hilfsfunktion zur Konvertierung von Datumsstrings in sortierbare Zahlenwerte
  getSortableDate(dateString: string): number {
    if (!dateString) return 0;

    // Bei Jahresangaben setzen wir das Datum auf den 1. Januar des Jahres
    if (/^\d{4}$/.test(dateString)) {
      return new Date(`${dateString}-01-01`).getTime();
    }

    // Bei Jahres- und Monatsangaben setzen wir den Tag auf den 1.
    if (/^\d{4}-\d{2}$/.test(dateString)) {
      return new Date(`${dateString}-01`).getTime();
    }

    // Bei vollständigen Datumsangaben verwenden wir diese direkt
    const date = new Date(dateString);
    if (!isNaN(date.getTime())) {
      return date.getTime();
    }

    // Unbekanntes Format
    return 0;
  }

  // Funktion zur Formatierung des Anzeigedatums
  formatDateStrict(dateString: string): string {
    if (!dateString) return "";

    // Nur Jahr vorhanden (z.B. "1818")
    if (/^\d{4}$/.test(dateString)) {
      return dateString;
    }

    // Jahr und Monat vorhanden (z.B. "1818-05")
    if (/^\d{4}-\d{2}$/.test(dateString)) {
      const [year, month] = dateString.split("-");
      return `${month}.${year}`;
    }

    // Vollständiges Datum vorhanden (z.B. "1818-05-20")
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
      const date = new Date(dateString);
      if (!isNaN(date.getTime())) {
        return this.datePipe.transform(date, "dd.MM.yyyy")!;
      }
    }

    // Andere Formate unverändert zurückgeben
    return dateString;
  }

  // Bilddaten verarbeiten
  // Bilddaten verarbeiten

  // HTML-Inhalte formatieren
  setFormattedHtmlContent() {
    // HTML-Inhalt zurücksetzen
    this.formattedHtmlContent = undefined;

    const rawHtmlContent = this.document?._source?.["4_html"];
    if (rawHtmlContent) {
      const processedHtml = this.processHtmlContent(rawHtmlContent);
      this.formattedHtmlContent =
        this.sanitizer.bypassSecurityTrustHtml(processedHtml);
    }
  }

  processHtmlContent(htmlContent: string): string {
    const categories = [
      { key: "index_organisationen_35", color: "#aeae00", gndKey: "gndorg" },
      { key: "index_berufe_31", color: "#afafd8", gndKey: "weblnk" },
      {
        key: "index_personenindex_8",
        color: "#80ff80",
        gndKey: "pers_gnd",
        fudKey: "pers_fudlink",
      }, // Add fudKey for person index
      { key: "index_raumindex_7", color: "#a3dffc", gndKey: "loc_gnd" },
    ];

    categories.forEach((category) => {
      const indexArray = this.document._source[category.key];
      if (indexArray) {
        indexArray.forEach((item: any) => {
          // Check for both gndKey and fudKey if it's a person index
          const tooltipContent = item.LmAdd?.[category.gndKey] || "";
          const fudLink = category.fudKey
            ? item.LmAdd?.[category.fudKey] || ""
            : "";

          // Pass both gnd and fudLink content in the dataContent
          const dataContent = JSON.stringify({
            content: tooltipContent.trim(),
            fudLink: fudLink.trim(), // Add fudLink to the data content
            key: category.key,
          });

          const regex = new RegExp(
            `(<span class="index-${item.ID} tp-[0-9]+)(.*?)">`,
            "g"
          );
          htmlContent = htmlContent.replace(regex, (match, p1, p2) => {
            return `<span class="index-${item.ID} tp-[0-9]+ underline dialog-link" style="background-color: ${category.color}; cursor: pointer;" data-content='${dataContent}'${p2}>`;
          });
        });
      }
    });

    return htmlContent;
  }

  // Reinitialisiere Links für Dialoge

  openDialog(): void {
    this.dialog.open(BookmarkDialogComponent, {
      data: this.bookmarkService.getMerklisteItems(),
    });
  }

  hasLitshorttitle(nachweise: any[]): boolean {
    if (!nachweise) return false;
    for (let nachweis of nachweise) {
      if (nachweis?.litshorttitle && nachweis.litshorttitle.length > 0) {
        return true;
      }
    }
    return false;
  }

  get merklisteCount(): number {
    return this.bookmarkService.getMerklisteCount();
  }

  navigateToDocument(documentId: string) {
    this.router.navigate(["/document", documentId]);
  }

  toSearch() {
    this.router.navigate(["/"]);
  }

  goToPrevDocument() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.navigateToDocument(this.documentIds[this.currentIndex]);
    }
  }

  goToNextDocument() {
    if (this.currentIndex < this.documentIds.length - 1) {
      this.currentIndex++;
      this.navigateToDocument(this.documentIds[this.currentIndex]);
    }
  }

  toggleBookmark(event: Event, document: any): void {
    event.stopPropagation();
    const bookmarkItem: BookmarkItem = {
      id: document._id,
      title: document._source.display_title,
    };
    this.bookmarkService.toggleItem(bookmarkItem.id, bookmarkItem.title);
    const message = this.bookmarkService.isInMerkliste(bookmarkItem.id)
      ? "Zur Merkliste hinzugefügt"
      : "Von Merkliste entfernt";
    this.snackBar.open(message, "", { duration: 2000 });
  }

  isBookmarked(document: any): boolean {
    return this.bookmarkService.isInMerkliste(document?._id);
  }

  loading = true; // Neue Variable für den Ladezustand

  processRelationships(relationships: any[]) {
    relationships.forEach((relationship: any) => {
      if (
        relationship.prelation2 &&
        relationship.prelation2[0].LmAdd.pers_fudlink
      ) {
        const fudLink = relationship.prelation2[0].LmAdd.pers_fudlink;

        // Debug-Information, um sicherzustellen, dass wir den richtigen fudLink überprüfen
        console.log("Prüfe fudLink:", fudLink);

        // Prüfe, ob ein Dokument für den `fudLink` existiert
        this.api.getDocument(fudLink).subscribe((response: any) => {
          if (response && response.hits && response.hits.total.value > 0) {
            // Es gibt mindestens ein Dokument, verwende den ersten Treffer
            const documentData = response.hits.hits[0]._source;
            console.log(
              `Dokument gefunden für fudLink ${fudLink}:`,
              documentData
            );

            // Setze die hasDocument-Flag auf true
            relationship.prelation2[0].hasDocument = true;
          } else {
            // Kein Dokument gefunden
            console.log(`Dokument NICHT gefunden für fudLink ${fudLink}`);
            relationship.prelation2[0].hasDocument = false;
          }

          // Erzwinge Änderungserkennung, um sicherzustellen, dass die UI aktualisiert wird
          this.cdr.detectChanges();
        });
      } else {
        console.log(
          "Kein fudLink für diese Beziehung:",
          relationship.prelation2
        );
        relationship.prelation2[0].hasDocument = false;
      }
    });

    this.relationships = relationships;
  }
  getRelationTypes(item: any): string {
    return item.dcterms_relation_type.map((type: any) => type.content).join(', ');
  }

  formatDate(dateString: string): string {
    const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('de-DE', options);
  }
}
