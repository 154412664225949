<mat-card
  class="mt-4"
  [ngClass]="{ active: dateRangeForm.get('isActive')?.value }"
  >
  <div
    class="d-flex justify-content-start align-items-center headline-searchForm text-primary-500 mb-0 mb-3 border-b-2 border-primary-500"
    >
    <mat-icon class="mr-2 text-primary-500">date_range</mat-icon>
    <p>Date range</p>
    <span class="line"></span>
  </div>

  <div class="p-2" [formGroup]="dateRangeForm">
    <div class="">
      <mat-form-field appearance="outline" class="col-md-6">
        <input
          matInput
          formControlName="start_range"
          placeholder="from (YYYY-MM-DD)"
          (blur)="formatStartRange()"
          />
          @if (dateRangeForm.get('start_range')?.hasError('invalidDate')) {
            <mat-error
              >
              Ungültiges Datum. Bitte geben Sie ein gültiges Datum ein.
            </mat-error>
          }
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-md-6">
          <input
            matInput
            formControlName="end_range"
            placeholder="to (YYYY-MM-DD)"
            (blur)="formatEndRange()"
            />
            @if (dateRangeForm.get('end_range')?.hasError('invalidDate')) {
              <mat-error
                >
                Invalid date. Please enter a valid date.
              </mat-error>
            }
            @if (dateRangeForm.hasError('rangeError')) {
              <mat-error>
                Start date cannot be later as end date.
              </mat-error>
            }
          </mat-form-field>
        </div>

        @if (!dateRangeForm.get('isActive')?.value) {
          <button
            mat-stroked-button
            color="primary text-primary-50"
            class="w-100"
            (click)="submitRange()"
            >
            <span class="">Submit</span>
          </button>
        }

        @if (dateRangeForm.get('isActive')?.value) {
          <button
            mat-flat-button
            color="primary text-primary-50"
            class="w-100"
            (click)="submitRange()"
            >
            <span class="text-primary-50">Zeitraumsuche aufheben</span>
          </button>
        }
      </div>
    </mat-card>
