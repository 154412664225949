import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface BookmarkItem {
  id: string;
  title: string;
}

@Injectable({
  providedIn: 'root'
})
export class BookmarkService {
  private readonly STORAGE_KEY = 'merkliste';
  private merklisteSubject = new BehaviorSubject<BookmarkItem[]>([]);
  private merkliste = new Map<string, BookmarkItem>();

  constructor() {
    this.loadState();
  }

  getMerklisteCount(): number {
    return this.merkliste.size;
  }

  private loadState() {
    try {
      const saved = localStorage.getItem(this.STORAGE_KEY);
      if (saved) {
        const items = JSON.parse(saved) as BookmarkItem[];
        this.merkliste = new Map(items.map(item => [item.id, item]));
      }
    } catch (error) {
      console.error('Error loading bookmarks:', error);
      this.merkliste = new Map();
    }
    this.updateSubject();
  }

  private saveState() {
    try {
      const items = Array.from(this.merkliste.values());
      localStorage.setItem(this.STORAGE_KEY, JSON.stringify(items));
      this.updateSubject();
    } catch (error) {
      console.error('Error saving bookmarks:', error);
    }
  }

  private updateSubject() {
    this.merklisteSubject.next(this.getMerklisteItems());
  }

  toggleItem(itemId: string, title: string) {
    // console.log('Attempting to toggle bookmark:', { itemId, title });

    if (!itemId || !title) {
      console.error('Invalid bookmark data:', { itemId, title });
      return;
    }

    const isCurrentlyBookmarked = this.merkliste.has(itemId);
    // console.log('Current bookmark status:', isCurrentlyBookmarked);

    if (isCurrentlyBookmarked) {
      this.merkliste.delete(itemId);
      // console.log('Removed bookmark:', itemId);
    } else {
      this.merkliste.set(itemId, { id: itemId, title });
      // console.log('Added bookmark:', { id: itemId, title });
    }

    this.saveState();
    // console.log('Current bookmarks:', this.getMerklisteItems());
  }

  isInMerkliste(itemId: string): boolean {
    const isBookmarked = itemId ? this.merkliste.has(itemId) : false;
    // console.log('Checking bookmark status:', { itemId, isBookmarked });
    return isBookmarked;
  }

  getMerklisteItems(): BookmarkItem[] {
    return Array.from(this.merkliste.values());
  }

  getMerklisteItemsObservable() {
    return this.merklisteSubject.asObservable();
  }
}
