import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
} from "@angular/core";
import { SubcontentComponent } from "../base/subcontent.component";
import {
  LinkService,
  MappingElementWithFUD,
} from "../../services/link.service";
import { ConverterService } from "../../services/converter.service";
import { DateService } from "../../services/date.service";
import { SubcontentService } from "../../services/subcontent.service";
import { MappingSubcontentLinkcontent } from "../../types/mapping/subcontent/extends/MappingSubcontentLinkcontent";
import { MappingSubcontentLinktarget } from "../../types/mapping/subcontent/extends/MappingSubcontentLinktarget";
import { MappingSubcontentLink } from "../../types/mapping/subcontent/extends/MappingSubcontentLink";

@Component({
  selector: "app-subcontent--link",
  templateUrl: "../base/subcontent.component.html",
  styleUrls: ["./link.subcontent.component.scss"],
})
export class LinkSubcontentComponent extends SubcontentComponent {
  @Input() override representedMappingElement!: MappingSubcontentLink;

  constructor(
    protected override linkService: LinkService,
    protected override converterService: ConverterService,
    protected override dateService: DateService,
    protected override componentRef: ElementRef,
    protected override subcontentService: SubcontentService,
    // protected override zone: NgZone,
    protected override cdr: ChangeDetectorRef
  ) {
    super(
      converterService,
      dateService,
      componentRef,
      linkService,
      subcontentService,
      // zone,
      cdr
    );
  }

  override init() {
    switch (this.representedMappingElement.typ) {
      default:
        switch (this.representedMappingElement.etype) {
          case "linkgroup":
            this.handleLinkgroup();
            break;
          default:
            console.error(
              "Created LinkSubcontentComponent but is not of etype linkgroup!"
            );
            // in case something unexpected happens, let the super class handle it
            super.init();
        }
    }
  }

  async handleLinkgroup() {
    let target: MappingElementWithFUD | null = null;
    let content: MappingSubcontentLinkcontent | null = null;
    let contentFromOtherDocument: boolean = false;
    let targetIndex = 0;
    let contentIndex = 1;
    if (this.representedMappingElement.subcontent[1].etype == "linktarget") {
      targetIndex = 1;
      contentIndex = 0;
    }
    const linktarget = this.representedMappingElement.subcontent[
      targetIndex
    ] as MappingSubcontentLinktarget;
    target = this.linkService.getTarget(
      linktarget.subcontent[0],
      this.exampleElement
    );
    content = this.representedMappingElement.subcontent[
      contentIndex
    ] as MappingSubcontentLinkcontent;
    contentFromOtherDocument = content.fromOtherDocument || false;
    if (!target && contentFromOtherDocument) {
      // field is not present in the document and no default display possible
      // if the content is in the same document, only the content without link will be displayed

      this.wrapper.oneChildMoreReady(); // make parent wrapper ready anyways
      return;
    }

    const wrapper = this.handleBoxing(1);
    this.createLinkSubComponent({
      representedMappingElement: content,
      id: this.id + " (link) ",
      exampleElement: this.exampleElement,
      wrapper: wrapper,
      childCount: 1,
      isLastBlockElement: this.isLastBlockElement,
      kTitles: this.kTitles,
      mTitles: this.mTitles,
      linkFromOtherDocument: contentFromOtherDocument,
      linkExtern: this.representedMappingElement.typ === "exlink",
      linkTarget: target ? target.documentValue : undefined,
      linkFudIndex: this.representedMappingElement.subcontent[1].qdoctyp,
    });
  }
}
