import { Component, ElementRef, Input, NgZone } from "@angular/core";
import { SubcontentComponent } from "../base/subcontent.component";
import { LinkService } from "../../services/link.service";
import { ConverterService } from "../../services/converter.service";
import { DateService } from "../../services/date.service";
import { SubcontentService } from "../../services/subcontent.service";
import { MappingSubcontentString } from "../../types/mapping/subcontent/extends/MappingSubcontentString";
import { ChangeDetectorRef } from "@angular/core";

@Component({
  selector: "app-subcontent--string",
  templateUrl: "../base/subcontent.component.html",
  styleUrls: ["./string.subcontent.component.scss"],
})
export class StringSubcontentComponent extends SubcontentComponent {
  @Input() override representedMappingElement!: MappingSubcontentString;

  constructor(
    protected override linkService: LinkService,
    protected override converterService: ConverterService,
    protected override dateService: DateService,
    protected override componentRef: ElementRef,
    protected override subcontentService: SubcontentService,
    // protected override zone: NgZone,
    protected override cdr: ChangeDetectorRef
  ) {
    super(
      converterService,
      dateService,
      componentRef,
      linkService,
      subcontentService,
      // zone,
      cdr
    );
  }

  override init() {
    switch (this.representedMappingElement.typ) {
      case "string":
        this.handleString();
        break;
      default:
        switch (this.representedMappingElement.etype) {
          default:
            console.error(
              "Created StringSubcontentComponent but is not of etype string!"
            );
            // in case something unexpected happens, let the super class handle it
            super.init();
        }
    }
  }

  handleString() {
    const wrapper = this.handleBoxing(0);
    const content = this.representedMappingElement.epart;
    if (!content) return;
    let str: string = "";
    str += content;
    this.subcontent.nativeElement.innerHTML = str;
    this.subcontent.nativeElement.classList.add("string-span");
    this.styleSpan(this.subcontent.nativeElement, wrapper);
  }
}
