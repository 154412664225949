import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { HttpService } from "./http.service";
import { Wrapper } from "../wrapper";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ConverterService {
  private url: string | undefined;

  constructor(private httpService: HttpService) {}

  private getFUD(mapping: FormGroup): string {
    const fud = mapping.controls["fud"];
    if (!fud) {
      console.error("Keine FUD-Version im Mapping angegeben.");
      return "";
    }
    return fud!.value;
  }

  public tryGetActiveIndexFromFields(completeMapping: FormGroup): string {
    if (
      completeMapping.controls["fud"] &&
      completeMapping.controls["fuddoctyp"]
    ) {
      return (
        "fud_" +
        completeMapping.controls["fud"]?.value +
        "_doctyp" +
        completeMapping.controls["fuddoctyp"]?.value
      );
    } else {
      console.error("FUD or Doctyp not given.");
      return "";
    }
  }

  public getWithoutMultiples(array: string[]): string[] {
    const ret: string[] = [];
    array.forEach((value: string) => {
      if (ret.indexOf(value) == -1) ret.push(value);
    });
    return ret;
  }

  public createHTMLElement(
    rootnode: HTMLElement,
    tag: string,
    id: string
  ): HTMLElement {
    const el = document.createElement(tag);
    el.id = id;
    rootnode.appendChild(el);
    return el;
  }

  public formFromJSON(json: any): FormGroup {
    const fb = new FormBuilder();
    const fg = fb.group({});
    return this.formFromGroup(fg, json, fb) as FormGroup;
  }

  private formFromGroup(parentForm: FormGroup, json: any, fb: FormBuilder) {
    for (const key in json) {
      if (Object.prototype.hasOwnProperty.call(json, key)) {
        const value = json[key];
        if (Array.isArray(value)) {
          const formArray: FormArray = fb.array([]);
          this.formFromArray(formArray, value, fb);
          parentForm.addControl(key, formArray);
        } else if (typeof value === "object" && value !== null) {
          const formGroup: FormGroup = fb.group({});
          parentForm.addControl(key, this.formFromGroup(formGroup, value, fb));
        } else {
          parentForm.addControl(key, new FormControl(value));
        }
      }
    }
    return parentForm;
  }

  private formFromArray(parentForm: FormArray, json: any, fb: FormBuilder) {
    for (const key in json) {
      if (Object.prototype.hasOwnProperty.call(json, key)) {
        const value = json[key];
        if (Array.isArray(value)) {
          const formArray: FormArray = fb.array([]);
          this.formFromArray(formArray, value, fb);
          parentForm.push(formArray);
        } else if (typeof value === "object" && value !== null) {
          const formGroup: FormGroup = fb.group({});
          parentForm.push(this.formFromGroup(formGroup, value, fb));
        } else {
          parentForm.push(new FormControl(value));
        }
      }
    }
    return parentForm;
  }

  public handleEclass(
    eclass: string[] | undefined,
    bootstrap: string | undefined,
    wrapper: HTMLElement | null,
    children: HTMLElement,
    parentWrapper?: Wrapper
  ) {
    if (!wrapper) return;

    const bootstrapClasses: string[] | undefined = bootstrap?.split(" ");
    if (bootstrapClasses) {
      for (const cl of bootstrapClasses) {
        if (!cl.startsWith('col-')) {
          wrapper.classList.add(cl);
        }
      }
    }

    if (!eclass || eclass == undefined) {
      return;
    }

    for (const cl of eclass) {
      if (cl?.length > 0 && !cl.startsWith('col-')) {
        wrapper.classList.add(cl);
      }
    }
  }

  public handleEpartPrePostClass(eclass: any, html: HTMLElement) {
    if (!html) return;
    if (!eclass || eclass == undefined) {
      return;
    }

    const classes: string[] = eclass;
    for (const cl of classes) {
      if (cl.length > 0 && !cl.startsWith('col-')) {
        html.classList.add(cl);
      }
    }
  }

  public handleElabelClass(eclass: any, label: HTMLElement, content: HTMLElement) {
    if (!eclass || eclass == undefined) {
      return;
    }

    const classes: string[] = eclass;
    for (const cl of classes) {
      if (cl.length > 0 && !cl.startsWith('col-')) {
        label.classList.add(cl);
      }
    }
  }

  public get(fc: FormControl, key: string | undefined): any {
    if (fc) {
      for (const [k, v] of Object.entries(fc)) {
        if (k == key) {
          return v;
        }
      }
    }
  }

  private checkIn(value: string, array: string[]) {
    for (let i = 0; i < array.length; i++) {
      if (array[i] == value) return true;
    }
    return false;
  }

  public async getURL(mapping: FormGroup): Promise<string> {
    if (this.url) {
      return this.url;
    } else {
      const urlIndex = this.tryGetActiveIndexFromFields(mapping);
      console.log("create url", urlIndex);
      this.url = urlIndex;
      return urlIndex;
    }
  }

  private async getDoctypIndicesForFUD(fudVersion: string): Promise<string[]> {
    const allIndices: string[] = [];
    return new Promise<string[]>((resolve, reject) => {
      this.httpService.getElasticIndices().then((indices: string[]) => {
        indices.forEach((index: string) => {
          if (index !== ".geoip_databases" && !index.includes("analyse")) {
            allIndices.push(index);
          }
        });
        if (allIndices.length <= 0) {
          console.error("Could not fetch elastic indices.");
          reject();
        }
        const ret: string[] = [];
        for (const doctyp of allIndices) {
          if (this.getFUDVersion(doctyp) == fudVersion) ret.push(doctyp);
        }
        resolve(ret);
      });
    });
  }

  private getFUDVersion(index: string): string {
    if (index == undefined) {
      console.warn("FUD-Version nicht definiert.");
      return "";
    }
    let ret: string;
    let regex = /fud_/;
    ret = index.replace(regex, "");
    regex = /_doctyp[0-9]*/;
    ret = ret.replace(regex, "");
    regex = /_analyse[\w]*/;
    ret = ret.replace(regex, "");
    return ret;
  }
}
