import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { BookmarkService } from "src/app/services/bookmark.service";
import { BookmarkItem } from "src/app/services/bookmark.service";

@Component({
  selector: "app-bookmark-dialog",
  templateUrl: "./bookmark-dialog.component.html",
  styleUrl: "./bookmark-dialog.component.scss",
})
export class BookmarkDialogComponent implements OnInit, OnDestroy {
  merklisteItems: BookmarkItem[] = [];
  private subscription: Subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<BookmarkDialogComponent>,
    private bookmarkService: BookmarkService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    // Subscribe to bookmark items observable
    this.subscription.add(
      this.bookmarkService.getMerklisteItemsObservable().subscribe((items) => {
        this.merklisteItems = items;
      })
    );
  }

  ngOnDestroy(): void {
    // Clean up subscription
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  // In BookmarkDialogComponent class
  public closeDialog(): void {
    this.dialogRef.close();
  }
  toDocument(id: any): void {
    // Close the dialog first
    this.dialogRef.close();

    // Navigate after a short delay to ensure the dialog close animation completes
    setTimeout(() => {
      this.router.navigate(["/document", id]);
    }, 150); // Adjust the delay as needed
  }

  toggleBookmark(event: Event, item: BookmarkItem): void {
    event.stopPropagation(); // Prevent the click event from propagating further
    this.bookmarkService.toggleItem(item.id, item.title); // Now passing both ID and title

    const message = this.bookmarkService.isInMerkliste(item.id)
      ? "Zur Merkliste hinzugefügt"
      : "Von Merkliste entfernt";
    this.snackBar.open(message, "", {
      duration: 2000,
    });
  }
}
