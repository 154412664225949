<div class="metadata-item">
  <!-- Label -->
  <div class="metadata-label" #label>
    <span class="text-gray-600 font-medium"></span>
  </div>

  <!-- Content Container -->
  <div class="metadata-content" #content>
    <!-- Prefix -->
    <span #pre></span>
    
    <!-- Dynamic Content -->
    <div class="content-wrapper" #subcontent>
      <ng-container #childrenContainer></ng-container>
    </div>
    
    <!-- Postfix -->
    <span #post></span>
  </div>
</div>