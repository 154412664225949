<div #wrap class="container-fluid">
  @if (hidden) {
    <div class="center">
      <mat-spinner></mat-spinner>
    </div>
  }
  <div [hidden]="hidden">
    <h1 #heading>
      <ng-container #childrenContainer></ng-container>
    </h1>
    <div #subcontent>
      <ng-container #childrenContainer></ng-container>
    </div>
  </div>
</div>
