import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
} from "@angular/core";
import { SubcontentComponent } from "../base/subcontent.component";
import { LinkService } from "../../services/link.service";
import { ConverterService } from "../../services/converter.service";
import { DateService } from "../../services/date.service";
import { SubcontentService } from "../../services/subcontent.service";
import { MappingSubcontentHtmltext } from "../../types/mapping/subcontent/extends/MappingSubcontentHtmltext";

@Component({
  selector: "app-subcontent--htmltext",
  templateUrl: "../base/subcontent.component.html",
  styleUrls: ["./htmltext.subcontent.component.scss"],
})
export class HtmltextSubcontentComponent extends SubcontentComponent {
  @Input() override representedMappingElement!: MappingSubcontentHtmltext;

  constructor(
    protected override linkService: LinkService,
    protected override converterService: ConverterService,
    protected override dateService: DateService,
    protected override componentRef: ElementRef,
    protected override subcontentService: SubcontentService,
    // protected override zone: NgZone,
    protected override cdr: ChangeDetectorRef
  ) {
    super(
      converterService,
      dateService,
      componentRef,
      linkService,
      subcontentService,
      // zone,
      cdr
    );
  }

  override init() {
    switch (this.representedMappingElement.typ) {
      default:
        switch (this.representedMappingElement.etype) {
          case "htmltext":
            this.handleHTMLtext();
            break;
          default:
            console.error(
              "Created HtmltextSubcontentComponent but is not of etype htmltext!"
            );
            // in case something unexpected happens, let the super class handle it
            super.init();
        }
    }
  }

  handleHTMLtext() {
    const wrapper = this.handleBoxing(0);
    if (!this.exampleElement) {
      return;
    }
    if (this.exampleElement.length > 0) {
      this.subcontent.nativeElement.innerHTML = this.exampleElement;
    }
    this.subcontent.nativeElement.classList.add("html-text-span");

    // handle ::externallink class
    let modifiedText = this.exampleElement as string;
    const linkRegex = /<[^>]*class="externallink::(.*?)">[^<]*<\/[^>]*>/g;
    const matches = modifiedText.match(linkRegex);
    if (matches) {
      for (const match of matches) {
        const subRegex = /<[^>]*class="externallink::(.*?)">(.*?)<\/[^>]*>/;
        const parts = match.match(subRegex);
        if (parts) {
          const element = parts[0];
          const url = parts[1];
          const linkText = parts[2];
          modifiedText = modifiedText.replace(
            element,
            `<a href="${url}" target="_blank">${linkText}</a>`
          );
        }
      }
      this.subcontent.nativeElement.innerHTML = modifiedText;
    }
    this.styleSpan(this.subcontent.nativeElement, wrapper);
  }
}
