<div class="container-fluid d-flex flex-column position-relative min-width">
  <!-- Close Button at the top right with absolute positioning -->
  <button mat-icon-button (click)="closeDialog()" class="close-btn m-3">
    <mat-icon>close</mat-icon>
  </button>

  <!-- Header with Title and Subtitle remains untouched -->
  <div class="ml-3">
    <h2 class="mb-0 mt-4">Merkliste</h2>
    <!-- <p class="text-muted mt-2">
      Übersicht über die Auflistung der Merklisten-Objekte
    </p> -->
  </div>

  <!-- List of Items -->
  @if (merklisteItems.length > 0) {
  <mat-list class="flex-grow-1 overflow-auto mb-4">
    @for (item of merklisteItems; track item) {
    <mat-list-item>
      <div class="d-flex justify-content-between align-items-center">
        <p class="m-0 flex-grow-1 text-truncate" (click)="toDocument(item.id)">
          {{
            (item.title || "").length > 50
              ? (item.title || "" | slice : 0 : 50) + "..."
              : item.title || ""
          }}
        </p>
        <button mat-icon-button (click)="toggleBookmark($event, item)">
          <mat-icon>remove</mat-icon>
        </button>
      </div>
    </mat-list-item>
    }
  </mat-list>
  } @else {
  <div class="d-flex justify-content-between align-items-center">
    {{ "Sie haben noch keine Dokumente zur Merkliste hinzugefügt." }}
  </div>
  }

  <!-- Removed the Footer with another close button to avoid redundancy -->
</div>
