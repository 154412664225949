<mat-card [ngClass]="{ active: queryStateService.isFullTextSearchActive() }">
  <div
    class="flex justify-start items-center headline-searchForm text-[#00678f] mb-0 mb-3 border-b-2 border-[#00678f]"
    >
    <mat-icon class="mr-2 text-[#00678f]">search</mat-icon>
    <p>Volltextsuche</p>
  </div>

  <mat-card-content>
    <div class="flex justify-center items-center">
      <mat-form-field appearance="outline" class="w-75 pr-2">
        <span matPrefix>
          <mat-icon class="ml-2">search</mat-icon>
        </span>
        <input
          matInput
          [formControl]="fullTextControl"
          placeholder="Suchbegriff eingeben"
          class="w-100"
          />
          @if (fullTextControl.value) {
            <button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="clearSearch()"
              >
              <mat-icon>close</mat-icon>
            </button>
          }
        </mat-form-field>

        <!-- FullTextSearchComponent template -->
        <mat-form-field appearance="outline" class="w-25">
          <mat-label>Sortieren</mat-label>
          <mat-select
            [formControl]="sortOptionControl"
            class="w-100"
            >
            @for (option of sortOptions; track option) {
              <mat-option [value]="option">
                {{ option.sortName }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-icon
          class="text-[#00678f] ml-2 mb-4 mr-2"
          matTooltip='You can use AND, OR, NOT, *, " " to refine your search.'
          >info</mat-icon
          >
        </div>
      </mat-card-content>
    </mat-card>
