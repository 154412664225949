import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { ConverterService } from "../../services/converter.service";
import { Wrapper } from "../../wrapper";
import { DateService } from "../../services/date.service";
import { LinkService } from "../../services/link.service";
import {
  LinkSubcontentComponentCreateRequest,
  SubcontentComponentCreateRequest,
} from "../../types/SubcontentComponentCreateRequest";
import { SubcontentService } from "../../services/subcontent.service";
import { MappingSubcontent } from "../../types/mapping/subcontent/MappingSubcontent";

@Component({
  selector: "app-subcontent",
  templateUrl: "./subcontent.component.html",
  styleUrls: ["./subcontent.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubcontentComponent implements OnInit, AfterViewInit {
  @Input() representedMappingElement!: MappingSubcontent;
  @Input() exampleElement: any;
  @Input() id!: string;
  @Input() wrapper!: Wrapper;
  @Input() childCount!: number;
  @Input() isLastBlockElement!: boolean;
  @Input() mTitles!: string[] | null;
  @Input() kTitles!: string[] | null;

  @ViewChild("outerContainer", { read: ViewContainerRef })
  outerContainer!: ViewContainerRef;
  @ViewChild("innerContainer", { read: ViewContainerRef })
  innerContainer!: ViewContainerRef;
  @ViewChild("childrenContainer", { read: ViewContainerRef })
  childrenContainer!: ViewContainerRef;
  @ViewChild("outerDefault") outerDefault!: TemplateRef<any>;
  @ViewChild("outerLink") outerLink!: TemplateRef<any>;
  @ViewChild("outerListItem") outerListItem!: TemplateRef<any>;
  @ViewChild("innerDefault") innerDefault!: TemplateRef<any>;
  @ViewChild("innerUnorderedList") innerUnorderedList!: TemplateRef<any>;
  @ViewChild("innerOrderedList") innerOrderedList!: TemplateRef<any>;

  @ViewChild("content") content!: ElementRef<HTMLSpanElement>;
  @ViewChild("subcontent") subcontent!: ElementRef<HTMLSpanElement>;
  @ViewChild("pre") prefix!: ElementRef<HTMLSpanElement>;
  @ViewChild("post") postfix!: ElementRef<HTMLSpanElement>;
  @ViewChild("label") label!: ElementRef<HTMLSpanElement>;

  constructor(
    protected converterService: ConverterService,
    protected dateService: DateService,
    protected componentRef: ElementRef, // reference to this components html element
    protected linkService: LinkService,
    protected subcontentService: SubcontentService,
    // protected zone: NgZone,
    protected cdr: ChangeDetectorRef // Dies muss übergeben werden
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.list();
    setTimeout(() => {
      this.init();
      this.cdr.detectChanges(); // Erzwinge einen erneuten Change Detection Zyklus
    });
  }

  // ngAfterViewInit(): void {
  //   this.list();
  //   this.zone.run(() => {
  //     this.init();
  //     this.cdr.detectChanges();
  //   });
  // }

  protected list(): void {
    const eclass = this.representedMappingElement.eclass;
    if (eclass?.includes("ul")) {
      this.innerContainer.clear();
      this.innerContainer.createEmbeddedView(this.innerUnorderedList);
    } else if (eclass?.includes("ol")) {
      this.innerContainer.clear();
      this.innerContainer.createEmbeddedView(this.innerOrderedList);
    }
    if (this.wrapper?.hasClass("ol") || this.wrapper?.hasClass("ul")) {
      this.outerContainer.clear();
      this.outerContainer.createEmbeddedView(this.outerListItem);
    }
  }

  init() {
    console.error(
      "illegal call: init method of component: ",
      this,
      " has been called."
    );
    console.log(
      "This may be because a SubcontentComponent has been created! Please use the subcontentService.create()-method instead to create a more specific version of this component.\n"
    );
    console.log("Is the mapping compatible to the newest converter version?");
  }

  protected handleBoxing(amountOfChildren: number): Wrapper {
    const wrapper = this.createWrapper(amountOfChildren);
    const wrap = wrapper.htmlWrapper;
    const subcontent = wrapper.subcontentDiv;
    const label = wrapper.labelSpan;
    if (!this.representedMappingElement.eclass) {
      if (this.wrapper?.hasClass("list")) {
        this.representedMappingElement.eclass = [];
        // if the parent wrapper has a list attribute we need an eclass array to handle the list children.
      }
    }
    this.converterService.handleEclass(
      this.representedMappingElement.eclass,
      this.representedMappingElement.bootstrap,
      wrap,
      subcontent,
      this.wrapper
    );
    if (wrap) {
      if (wrapper.hasClass("labelobj")) {
        wrap.style.width = "20%";
        wrap.style.float = "left";
        if (wrapper.parentWrapper?.htmlWrapper)
          wrapper.parentWrapper!.htmlWrapper.style.overflow = "auto";
        label.style.width = "0%";
      }
      if (wrapper.hasClass("80percent")) {
        wrap.style.width = "80%";
      }
    }
    return wrapper;
  }

  protected createWrapper(amountOfChildren: number): Wrapper {
    const label = this.createLabel();
    this.prePost();
    const contentDiv = this.content.nativeElement;
    const subcontentDiv = this.subcontent.nativeElement;
    if (!this.componentRef.nativeElement) {
      console.log(this.representedMappingElement);
    }
    const wrapper = new Wrapper(
      this.componentRef.nativeElement,
      this.wrapper,
      label,
      contentDiv,
      subcontentDiv,
      amountOfChildren,
      this.representedMappingElement
    );
    if (label.innerHTML == "") {
      //contentDiv.classList.add("col-md-12");
    } else {
      // label.classList.add("col-md-4");
      // label.style.float = "left";
      // contentDiv.classList.add("col-md-8");
      // contentDiv.style.float = "right";
    }
    return wrapper;
  }

  protected createLabel(): HTMLElement {
    const html = this.label.nativeElement;
    let labelstring = "";
    if (this.representedMappingElement.elabel) {
      this.representedMappingElement.elabel.forEach((label: any) => {
        if (label.labelstring) {
          labelstring = label.labelstring;
        }
        html.innerHTML = labelstring;
        if (label.eclass) {
          this.converterService.handleEpartPrePostClass(label.eclass, html);
        }
        if (label.lang) {
          // TODO language handling
        }
      });
    }
    return html;
  }

  protected prePost() {
    const prefixSpan = this.prefix.nativeElement;
    const prefix = this.representedMappingElement.epartpre;
    if (prefix) {
      if (prefix.epartpre) {
        prefixSpan.innerHTML = prefix.epartpre;
        this.converterService.handleEpartPrePostClass(
          prefix.eclass,
          prefixSpan
        );
      }
    }
    const postfixSpan = this.postfix.nativeElement;
    const postfix = this.representedMappingElement.epartpost;
    if (postfix) {
      if (postfix.epartpost) {
        if (
          this.isLastBlockElement &&
          postfix.eclass !== undefined &&
          postfix.eclass.includes("without-last")
        ) {
          // leave out epartpost
        } else {
          postfixSpan.innerHTML = postfix.epartpost;
        }
        this.converterService.handleEpartPrePostClass(
          postfix.eclass,
          postfixSpan
        );
      }
    }
  }

  protected styleSpan(span: HTMLElement, wrapper: Wrapper) {
    if (wrapper.ancestorOrSelfHasClass("fudvalue")) {
      span.style.fontWeight = "200";
    }
    if (wrapper.ancestorOrSelfHasClass("fudvaluehighlight")) {
      span.style.fontWeight = "200";
      span.style.backgroundColor = "#EACF57";
    }
  }

  protected createNextSubcontentComponent(
    v: any,
    mappingSubcontent: MappingSubcontent,
    subcontentIndex: number,
    wrapper: Wrapper,
    mappingsLength: number,
    isLastBlockElement: boolean
  ) {
    const nextExampleElement = this.converterService.get(
      v as FormControl,
      mappingSubcontent.epart
    );
    if (nextExampleElement != undefined) {
      this.createSubComponent({
        representedMappingElement: mappingSubcontent,
        id: this.id + " " + subcontentIndex,
        exampleElement: nextExampleElement,
        wrapper: wrapper,
        childCount: mappingsLength,
        isLastBlockElement: isLastBlockElement,
        kTitles: this.kTitles,
        mTitles: this.mTitles,
      });
    } else if (mappingSubcontent.etype === "linkgroup") {
      // get children as example elements
      this.createSubComponent({
        representedMappingElement: mappingSubcontent,
        id: this.id + " " + subcontentIndex,
        exampleElement: v,
        wrapper: wrapper,
        childCount: mappingsLength,
        isLastBlockElement: isLastBlockElement,
        kTitles: this.kTitles,
        mTitles: this.mTitles,
      });
    } else if (v !== undefined) {
      // continue with parents epart for some types of subcontent
      switch (mappingSubcontent.typ) {
        case "group":
        case "all_files":
          this.createSubComponent({
            representedMappingElement: mappingSubcontent,
            id: this.id + " " + subcontentIndex,
            exampleElement: v,
            wrapper: wrapper,
            childCount: mappingsLength,
            isLastBlockElement: isLastBlockElement,
            kTitles: this.kTitles,
            mTitles: this.mTitles,
          });
          break;
        default:
          console.warn("No content for mapping element ", mappingSubcontent);
          this.wrapper.oneChildMoreReady();
      }
    } else {
      for (let i = 0; i < mappingsLength; i++) {
        this.wrapper.oneChildMoreReady(); // make mapping children ready even when no fud representation exists.
      }
    }
  }

  protected static isLinkElem(
    representedMappingElement: MappingSubcontent
  ): boolean {
    if (
      (representedMappingElement &&
        representedMappingElement.typ === "link_group") ||
      representedMappingElement.typ === "link_fud" ||
      representedMappingElement.typ === "link_string" ||
      representedMappingElement.etype === "linkcontent"
    ) {
      return true;
    }
    return false;
  }

  protected createSubComponent(
    request: SubcontentComponentCreateRequest
  ): Promise<ComponentRef<SubcontentComponent>> {
    return this.subcontentService.createSubComponent(
      this.childrenContainer,
      request
    );
  }

  protected createLinkSubComponent(
    request: LinkSubcontentComponentCreateRequest
  ): Promise<ComponentRef<SubcontentComponent>> {
    return this.subcontentService.createLinkSubComponent(
      this.childrenContainer,
      request
    );
  }
}
