<div class="container-fluid">
  <div class="row">
    <!-- Left Mini Sidebar -->
    <div class="col-auto min-vh-100">
      <button
        mat-flat-button
        color="primary"
        class="w-100 p-3 mt-5"
        (click)="toSearch()"
        matTooltip="Zurück zur Trefferliste"
      >
        <mat-icon class="text-primary-50">arrow_back_ios</mat-icon>
        <span class="text-primary-50">Zurück zur Trefferliste</span>
      </button>
    </div>

    <!-- Main Content Area -->
    <div class="col px-4 mt-5">
      <div class="mx-auto" style="max-width: 70rem">
        <!-- Header Section with Title Mapping -->
        <header class="bg-white shadow-lg px-4 py-3 sticky-top">
          <div class="d-flex justify-content-between align-items-center">
            <button
              (click)="goToPrevDocument()"
              mat-icon-button
              class="me-2"
              matTooltip="Vorheriges Dokument"
              [disabled]="currentIndex <= 0"
            >
              <mat-icon>arrow_back_ios</mat-icon>
            </button>

            <div class="w-full">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <app-converter-view
                    [id]="id$"
                    [mappingComponentType]="'title'"
                    class="custom-converter"
                  ></app-converter-view>
                </div>

                <div>
                  @if (document['_source']['5_sourcename0']) {

                  <img
                    src="/assets/vmimg/mid/mid_{{
                      document['_source']['5_sourcename0']
                    }}"
                    alt="Screenshot"
                  />

                  }
                </div>
              </div>
            </div>

            <button
              (click)="goToNextDocument()"
              mat-icon-button
              matTooltip="Nächstes Dokument"
              [disabled]="currentIndex >= documentIds.length - 1"
            >
              <mat-icon>arrow_forward_ios</mat-icon>
            </button>
          </div>
        </header>

        <!-- Content Area -->
        <div class="py-4">
          <div class="row">
            <!-- Main Content Column -->
            <div class="col-lg-7 mb-4">
              <div class="bg-white shadow-lg rounded p-4">
                <app-converter-view
                  [id]="id$"
                  [mappingComponentType]="'fulltext'"
                  class="custom-converter"
                ></app-converter-view>
              </div>
              @if (document._source['5_Nachweis'] &&
              document._source['5_Nachweis'].length > 0) {
              <div class="bg-white shadow-lg rounded p-4 mt-4">
                <!-- Wenn die verbundene Ressource ein FuD-Dokument ist, wird nur der Link zum Dokument und die Art der Relation ausgegeben.
                    Wenn die verbundene Ressource eine URL ist, wird der Titel, der Link, das Datum des letzten Zugriffs und die Art der Relation ausgegeben. -->

                <div class="row">
                  <div class="col-md-12"><h2>Verbundene Ressourcen</h2></div>
                </div>
                @for (item of document._source['5_Nachweis']; track item; let
                last = $last) { @if (item.dcterms_relation) {
                <div class="row">
                  <div class="col-md-4">Dokument</div>
                  <div class="col-md-8 content-wrap align-repeatable-block">
                    <a href="#/document/{{ item.dcterms_relation }}">{{
                      item.relTitle
                    }}</a>
                  </div>
                </div>
                } @else if (item.dcterms_titel_relation_url) {
                <div class="row">
                  <div class="col-md-4">Titel</div>
                  <div class="col-md-8 content-wrap align-repeatable-block">
                    {{ item.dcterms_titel_relation_url }}
                  </div>
                </div>
                @if (item.dcterms_relation_url) {
                <div class="row">
                  <div class="col-md-4">URL</div>
                  <div class="col-md-8 content-wrap align-repeatable-block">
                    <a [href]="item.dcterms_relation_url">{{
                      item.dcterms_relation_url
                    }}</a>
                  </div>
                </div>
                } @if (item.dcterms_lastdate_url) {
                <div class="row">
                  <div class="col-md-4">Letzter Zugriff</div>
                  <div class="col-md-8 content-wrap align-repeatable-block">
                    {{ formatDate(item.dcterms_lastdate_url) }}
                  </div>
                </div>
                } } @if (item.dcterms_relation_type &&
                item.dcterms_relation_type.length > 0) {
                <div class="row">
                  <div class="col-md-4">Art der Beziehung</div>
                  <div class="col-md-8 content-wrap align-repeatable-block">
                    {{ getRelationTypes(item) }}
                  </div>
                </div>
                } @if (!last) {
                <hr class="my-2 border-2" />
                } }
              </div>
              }
            </div>

            <!-- Sidebar Column -->
            <div class="col-lg-5">
              <div
                class="bg-white shadow-lg rounded p-4 sticky-top"
                style="top: 5rem"
              >
                <app-converter-view
                  [id]="id$"
                  [mappingComponentType]="'content'"
                  class="custom-converter vertical_view"
                ></app-converter-view>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Right Mini Sidebar -->
    <div
      class="col-auto min-vh-100 bg-white shadow-lg d-flex flex-column"
      style="width: 4rem"
    >
      <!-- Mini-fab Icons -->
      <div
        class="flex-grow-1 d-flex flex-column align-items-center justify-content-center"
      >
        <button
          mat-icon-button
          class="mb-3"
          (click)="toggleBookmark($event, this.document)"
        >
          <mat-icon
            [ngClass]="{
              'text-secondary-500': isBookmarked(this.document),
              'text-primary-500': !isBookmarked(this.document)
            }"
            >{{
              isBookmarked(this.document) ? "bookmark_added" : "bookmark_add"
            }}</mat-icon
          >
        </button>

        <button
          mat-icon-button
          color="primary"
          class="mb-3"
          matBadge="merklisteCount"
          matBadgeOverlap="true"
          (click)="openDialog()"
          matTooltip="Merkliste"
        >
          <mat-icon>bookmarks</mat-icon>
        </button>

        <button
          mat-icon-button
          disabled
          color="primary"
          class="mb-3"
          matTooltip="Drucken"
        >
          <mat-icon>print</mat-icon>
        </button>

        <button
          mat-icon-button
          disabled
          color="primary"
          class="mb-3"
          matTooltip="Hilfe"
        >
          <mat-icon>help</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
