import { Component, ViewChild } from "@angular/core";
import { MatDrawer } from "@angular/material/sidenav";
import { BookmarkService } from "../services/bookmark.service";
import { BookmarkDialogComponent } from "../dialogs/bookmark-dialog/bookmark-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { QueryStateService } from "../services/query-state.service"; 
import { QueryService } from "../services/query.service"; 
import { ApiService } from "../services/api.service"; 
import { MatSnackBar } from "@angular/material/snack-bar"; 

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent {
  @ViewChild("drawer") drawer!: MatDrawer;
  searchResults: any; 
  printResults: any[] = [];
  isPrinting = false;

  constructor(
    public dialog: MatDialog,
    public bookmarkService: BookmarkService,
    private queryStateService: QueryStateService, 
    private queryService: QueryService, 
    private apiService: ApiService, 
    private snackBar: MatSnackBar 
  ) {}

  toggleDrawer() {
    this.drawer.toggle();
  }

  openDialog(): void {
    this.dialog.open(BookmarkDialogComponent, {
      minWidth: 600,
      minHeight: 400,
      data: this.bookmarkService.getMerklisteItems(), 
    });
  }

  get merklisteCount(): number {
    return this.bookmarkService.getMerklisteCount(); 
  }

  print() {
    // Hole aktuelle Query und wandle sie in Print-Query um
    const printQuery = this.queryService.buildPrintQuery(this.queryStateService.getQueryState());
    
    // Hole alle Ergebnisse für den Druck
    this.apiService.search(printQuery).subscribe({
      next: (response) => {
        this.printResults = response.hits.hits;
        this.isPrinting = true;
        
        // Öffne Print-Dialog
        setTimeout(() => {
          window.print();
          // Stelle ursprünglichen Zustand wieder her
          setTimeout(() => {
            this.isPrinting = false;
            this.printResults = [];
          }, 500);
        }, 100);
      },
      error: (error) => {
        console.error('Error fetching print results:', error);
        this.snackBar.open('Fehler beim Laden der Druckansicht', 'OK', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
      }
    });
  }
}
