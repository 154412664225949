import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { QueryState } from '../models/query-state.model';

@Injectable({
  providedIn: 'root'
})
export class QueryStateService {
  public chipRemoved = new EventEmitter<any>();

  // Add an event emitter for reset notifications
  private resetEvent = new BehaviorSubject<boolean>(false);
  resetEventObservable = this.resetEvent.asObservable();

  private queryState = new BehaviorSubject<QueryState>({
    fullText: '',
    specificFields: {
      fields: [],
      relationship: 'AND', // Default relationship
    },
    dateRange: null,
    docTypeFilters: {},
    aggregations: {
      
    }, 
    size: 25,
    from: 0,
    sortOption: { sortField: '_score', sortMethod: 'desc' },
  });

  queryStateObservable = this.queryState.asObservable();
  private isLoading = new BehaviorSubject<boolean>(false);
  
  // Methode, um den Ladezustand abzurufen
  isLoading$ = this.isLoading.asObservable();
  allHits: any;
  allObjects: any;

  // Methoden zum Aktualisieren des Ladezustands
  setLoading(isLoading: boolean): void {
    this.isLoading.next(isLoading);
  }

  updatePageSize(size: number): void {
    const currentState = this.queryState.getValue();
    this.queryState.next({ ...currentState, size });
  }
  
  updatePageIndex(index: number): void {
    const currentState = this.queryState.getValue();
    const from = index * currentState.size; // Berechne 'from' basierend auf dem aktuellen 'size'
    this.queryState.next({ ...currentState, from });
  }

  setFullText(fullText: string) {
    const currentState = this.queryState.getValue();
    this.queryState.next({ ...currentState, fullText });
  }

  setSpecificFields(specificFields: Array<{ field: string; value: string }>, relationship: 'AND' | 'OR'): void {
    const currentState = this.queryState.getValue();
    // Entferne leere oder ungültige Felder
    console.log(specificFields);
    const validFields = specificFields.filter(field => field.field && field.value);
    if (validFields.length > 0) {
      currentState.specificFields = { fields: validFields, relationship };
    } else {
      // Wenn keine gültigen Felder vorhanden sind, setze specificFields zurück
      currentState.specificFields = { fields: [], relationship: 'AND' }; // oder behalte die aktuelle Beziehung bei
    }
    this.queryState.next(currentState);
  }


  resetSpecificFields(): void {
    const currentState = this.queryState.getValue();
    currentState.specificFields = { fields: [], relationship: 'AND' }; // Zurücksetzen auf Standardwerte
    this.queryState.next(currentState);
    console.log('Specific fields reset.');
  }
  

  setDateRange(start: string, end: string, isActive: boolean) {
    const currentState = this.queryState.getValue();
    // Überprüfe, ob sich der Zustand tatsächlich ändert
    if (currentState.dateRange?.start !== start || currentState.dateRange.end !== end || currentState.dateRange.isActive !== isActive) {
      this.queryState.next({...currentState,
        dateRange: {
          start,
          end,
          isActive
        }
      });
    }
  }
  
  setDocTypeFilters(docTypeFilters: { [key: string]: boolean }) {
    const currentState = this.queryState.getValue();
    this.queryState.next({ ...currentState, docTypeFilters });
    console.log('Updated docTypeFilters:', this.queryState.getValue().docTypeFilters);
  }

  // Füge eine Aggregation hinzu
  addAggregation(aggKey: string, facetKey: string, label: string): void {
    const currentState = this.queryState.getValue();
    const updatedAggregations = {
      ...currentState.aggregations,
      [aggKey]: currentState.aggregations[aggKey]
        ? [...currentState.aggregations[aggKey], { value: facetKey }]
        : [{ value: facetKey }],
    };

    this.queryState.next({
      ...currentState,
      aggregations: updatedAggregations,
    });
  }

  // Entferne eine Aggregation
  removeAggregation(aggKey: string, facetKey: string): void {
    const currentState = this.queryState.getValue();
    const updatedAggregations = {
      ...currentState.aggregations,
      [aggKey]: currentState.aggregations[aggKey]?.filter(
        (item) => item.value !== facetKey
      ),
    };

    // Entferne leere Arrays
    if (!updatedAggregations[aggKey]?.length) {
      delete updatedAggregations[aggKey];
    }

    this.queryState.next({
      ...currentState,
      aggregations: updatedAggregations,
    });
  }

  // Prüfe ob eine Aggregation ausgewählt ist
  isAggregationSelected(aggKey: string, facetKey: string): boolean {
    const currentState = this.queryState.getValue();
    return currentState.aggregations[aggKey]?.some(
      (item) => item.value === facetKey
    ) || false;
  }

  setSortOption(sortOption: { sortField: string; sortMethod: string } | null) {
    const currentState = this.queryState.getValue();
    this.queryState.next({ ...currentState, sortOption });
  }

  resetQueryState() {


    
    this.queryState.next({
      fullText: '',
      specificFields: {
        fields: [],
        relationship: 'AND', // Default relationship
      },
      dateRange: null,
      docTypeFilters: {},
      aggregations: {}, 
      size: 25,
      from: 0,
      sortOption: null, // Use undefined here
    });
    // Notify subscribers that a reset has occurred
    this.resetEvent.next(true);
  }

  getQueryState(): QueryState {
    return this.queryState.value;
  }

  // CHECK ACTIVE STATE
  isFullTextSearchActive(): boolean {
    return this.queryState.getValue().fullText.trim() !== '';
  }

  isFieldSpecificSearchActive(): boolean {
    return this.queryState.getValue().specificFields.fields.length > 0;
  }

  isDateRangeActive(): boolean {
    const range = this.queryState.getValue().dateRange;
    return range !== null && range.isActive;
  }

  isDocTypeFilterActive(): boolean {
    const filters = this.queryState.getValue().docTypeFilters;
    // Gibt true zurück, wenn das Objekt leer ist oder mindestens ein Filter false ist
    return Object.values(filters).some(value => !value);
  }
  
  isAggregationFilterActive(): boolean {
    const aggregations = this.queryState.getValue().aggregations;
    // Gibt true zurück, wenn in irgendeinem Aggregationsfilter mindestens ein Begriff ausgewählt wurde
    return Object.values(aggregations).some(terms => terms.length > 0);
  }
}
