<div class="chip-container pl-3">
  <!-- <mat-chip-grid #chipGrid aria-label="Chip selection"> -->
  <!-- Volltextsuche -->
  @if (queryState.fullText) {
    <mat-chip
      class="mr-1 mt-2 mb-2 text-primary-50"
      highlighted="true"
      >
      <span class="text-primary-50">Volltext: {{ queryState.fullText }}</span>
    </mat-chip>
  }

  <!-- Spezifische Feldfilter -->
  @for (field of queryState.specificFields.fields; track field) {
    <mat-chip
      class="mr-1 mt-2 mb-2 text-primary-50"
      highlighted="true"
      >
      <span class="text-primary-50">Filter: {{ field.value }}</span>
    </mat-chip>
  }

  <!-- Datumsspanne -->
  @if (queryState.dateRange && queryState.dateRange.isActive) {
    <mat-chip
      class="mr-1 mt-2 mb-2 text-primary-50"
      highlighted="true"
      >
      <span class="text-primary-50"
        >Datumsspanne: {{ queryState.dateRange.start }} bis
        {{ queryState.dateRange.end }}</span
        >
      </mat-chip>
    }

    <!-- DoctypVorfilter (nur inaktiv) -->
    @for (doctyp of queryState.docTypeFilters | keyvalue; track doctyp) {
      @if (!doctyp.value) {
        <mat-chip
          class="mr-1 mt-2 mb-2 text-primary-50"
          highlighted="true"
          >
          <span class="text-primary-50">Doctyp: {{ doctyp.key }}</span>
        </mat-chip>
      }
    }

    <!--  queryState.aggregations = { "facet_location": [ "Düsseldorf, Haus des Ministerpräsidenten, Haroldstraße 2" ], "facet_doctyp": [ "Protokoll" ], "facet_legislaturperiode": [ "2" ] } -->
    <!-- Aggregationen -->
    @for (aggKey of objectKeys(queryState.aggregations); track aggKey) {
      @for (aggItem of queryState.aggregations[aggKey]; track aggItem) {
        <mat-chip
          class="mr-1 mt-2 mb-2 "
          highlighted="true"
          >
          <span >{{ aggItem.value }}</span>
          <button matChipRemove (click)="removeAggregation(aggKey, aggItem.value)">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
      }
    }
  <!-- </mat-chip-grid> -->
</div>
