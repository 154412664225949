<mat-drawer-container class="calcHeight" autosize>
  <mat-drawer #drawer opened class="w-25" mode="side">
    <app-search-form></app-search-form>
  </mat-drawer>

  <div class="drawer-content-container">
    <button
      class="position-absolute rotate"
      type="button"
      mat-mini-fab
      color="primary"
      (click)="toggleDrawer()"
    >
      <mat-icon [class]="drawer.opened ? 'rotate-icon open' : 'rotate-icon'"
        >chevron_left</mat-icon
      >
    </button>

    <div class="w-100 position-relative">
      <app-list-item></app-list-item>
      <app-chip-list></app-chip-list>
    </div>

    <div class="side-mini bg-gray-100">
      <button
        mat-icon-button
        color="primary"
        matBadge="merklisteCount"
        matBadgeOverlap="false"
        (click)="openDialog()"
        matTooltip="Merkliste"
      >
        <mat-icon>bookmarks</mat-icon>
      </button>

      <!-- <button
        mat-icon-button
        class="toggle-button"
        (click)="drawer.toggle()"
        aria-label="Toggle sidenav"
      >
        <mat-icon>chevron_left</mat-icon>
      </button> -->

      <button
        mat-icon-button
        color="primary"
        aria-label="Print search results"
        matTooltip="Drucken"
        (click)="print()"
      >
        <mat-icon>print</mat-icon>
      </button>
      <!-- <button
        mat-icon-button
        disabled
        color="primary"
        aria-label="Example icon button with a menu icon"
        matTooltip="Hilfe"
      >
        <mat-icon>help</mat-icon>
      </button> -->
      <!-- <button mat-icon-button color="primary" aria-label="Example icon button with a heart icon">
            <mat-icon>documentation</mat-icon>
          </button>
          <button mat-icon-button disabled aria-label="Example icon button with a open in new tab icon">
            <mat-icon>open_in_new</mat-icon>
          </button> -->
    </div>
  </div>
</mat-drawer-container>
