<div class="container search-view">
  <button class="suche" mat-raised-button color="primary">Suche</button>
  <button class="zuruecksetzen" mat-button>Zurücksetzen</button>
</div>

<div class="container small-view pt-3">
  <app-full-text-search></app-full-text-search>
  <!-- <app-specific-field-search></app-specific-field-search> -->
  <!-- <app-pre-filter></app-pre-filter> -->
  <!-- <app-date-range></app-date-range> -->
  <app-aggregations></app-aggregations>

  <br />
  <br />
</div>
