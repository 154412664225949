/*
 * CONVERTER CONFIG
 */

// which are the names of the mappings in src/assets/mappings ?
export const mappingNames: string[] = ["n4mdlvh_content.json", "n4mdlvh_title.json", "n4mdlvh_fulltext.json"];


// To which route should the router navigate when using links?
export const route: string = "document";

// Should links write the id of the document in a query parameter (true) or in a normal route parameter (false)?
export const query: boolean = false;

// Which methods should be evaluated to input the document id in the converter?
export const inputMethods = {
  angularComponentInput: true, // @Input('id')
  routeParameter: true, // url/#/path/:id
  routeQueryParameter: true, // url/path?id=123
};

/*
 * HTTP CONFIG
 */

// Do you want to use express.js or php?
export const useExpress: boolean = false;

// Where is your projects environment config?
import { environment as stdEnv } from "src/environments/environment";
export const standardEnvironment = stdEnv;
