import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
} from "@angular/core";
import { SubcontentComponent } from "../base/subcontent.component";
import { LinkService } from "../../services/link.service";
import { ConverterService } from "../../services/converter.service";
import { DateService } from "../../services/date.service";
import { SubcontentService } from "../../services/subcontent.service";
import { MappingSubcontent } from "../../types/mapping/subcontent/MappingSubcontent";
import { MappingSubcontentChild } from "../../types/mapping/subcontent/extends/MappingSubcontentChild";

@Component({
  selector: "app-subcontent--child",
  templateUrl: "../base/subcontent.component.html",
  styleUrls: ["./child.subcontent.component.scss"],
})
export class ChildSubcontentComponent extends SubcontentComponent {
  @Input() override representedMappingElement!: MappingSubcontentChild;

  constructor(
    protected override linkService: LinkService,
    protected override converterService: ConverterService,
    protected override dateService: DateService,
    protected override componentRef: ElementRef,
    protected override subcontentService: SubcontentService,
    // protected override zone: NgZone,
    protected override cdr: ChangeDetectorRef
  ) {
    super(
      converterService,
      dateService,
      componentRef,
      linkService,
      subcontentService,
      // zone,
      cdr
    );
  }

  override init() {
    switch (this.representedMappingElement.typ) {
      default:
        switch (this.representedMappingElement.etype) {
          case "child":
            this.handleChild();
            break;
          default:
            console.error(
              "Created ChildSubcontentComponent but is not of etype child!"
            );
            // in case something unexpected happens, let the super class handle it
            super.init();
        }
    }
  }

  handleChild() {
    const mappingSubcontents = this.representedMappingElement.subcontent;
    const wrapper = this.handleBoxing(mappingSubcontents.length);

    mappingSubcontents.forEach(
      (mappingSubcontent: MappingSubcontent, subcontentIndex: number) => {
        const nextExampleElement = this.converterService.get(
          this.exampleElement,
          mappingSubcontent.epart
        );
        if (nextExampleElement != undefined) {
          this.createSubComponent({
            representedMappingElement: mappingSubcontent,
            id: this.id + " " + subcontentIndex,
            exampleElement: nextExampleElement,
            wrapper: wrapper,
            childCount: mappingSubcontents.length,
            isLastBlockElement: false,
            kTitles: this.kTitles,
            mTitles: this.mTitles,
          });
        } else if (
          mappingSubcontent.etype === "linkgroup" ||
          mappingSubcontent.typ === "group"
        ) {
          // stay with this epart
          this.createSubComponent({
            representedMappingElement: mappingSubcontent,
            id: this.id + " " + subcontentIndex,
            exampleElement: this.exampleElement,
            wrapper: wrapper,
            childCount: mappingSubcontents.length,
            isLastBlockElement: false,
            kTitles: this.kTitles,
            mTitles: this.mTitles,
          });
        } else {
          this.wrapper.oneChildMoreReady(); // if no fud representation can be found, just increase number of ready children on parent wrapper
        }
      }
    );
  }
}
