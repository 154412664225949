import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { QueryStateService } from '../../../services/query-state.service';
import { SEARCH_CONFIG } from 'src/search-config';
import { debounceTime } from 'rxjs/operators';

interface SortOption {
  sortName: string;
  sortField: string;
  sortMethod: string;
}

@Component({
  selector: 'app-full-text-search',
  templateUrl: './full-text-search.component.html',
  styleUrls: ['./full-text-search.component.scss']
})
export class FullTextSearchComponent implements OnInit {
  fullTextControl = new FormControl('');
  sortOptionControl = new FormControl<SortOption | null>(SEARCH_CONFIG.sort[0]);
  sortOptions = SEARCH_CONFIG.sort;

  constructor(public queryStateService: QueryStateService) {
    this.queryStateService.queryStateObservable.subscribe(queryState => {
      if (queryState.fullText !== this.fullTextControl.value) {
        this.fullTextControl.setValue(queryState.fullText, { emitEvent: false });
      }

      if (queryState.sortOption) {
        // Find matching sort option from config
        const matchingOption = this.sortOptions.find(opt => 
          opt.sortField === queryState.sortOption?.sortField && 
          opt.sortMethod === queryState.sortOption?.sortMethod
        ) || this.sortOptions[0];
        
        this.sortOptionControl.setValue(matchingOption, { emitEvent: false });
      } else {
        this.sortOptionControl.setValue(this.sortOptions[0], { emitEvent: false });
      }
    });
  }

  ngOnInit() {
    this.queryStateService.queryStateObservable.subscribe((queryState) => {
      if (queryState.fullText !== this.fullTextControl.value) {
        this.fullTextControl.setValue(queryState.fullText, { emitEvent: false });
      }
    });

    this.fullTextControl.valueChanges
      .pipe(debounceTime(300))
      .subscribe((value) => {
        this.queryStateService.setFullText(value || '');
      });

    this.sortOptionControl.valueChanges.subscribe(value => {
      if (value) {
        this.queryStateService.setSortOption({
          sortField: value.sortField,
          sortMethod: value.sortMethod
        });
      }
    });
  }

  clearSearch() {
    this.fullTextControl.setValue('');
  }
}
