import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SearchFormComponent } from "./search/search-form/search-form.component";
import { SearchComponent } from "./search/search.component";
import { DocumentViewComponent } from "./document-view/document-view.component";
import { FullTextSearchComponent } from "./search/search-form/full-text-search/full-text-search.component";
import { FieldSpecificSearchComponent } from "./search/search-form/specific-field-search/specific-field-search.component";
import { AggregationsComponent } from "./search/search-form/aggregations/aggregations.component";
import { DateRangeComponent } from "./search/search-form/date-range/date-range.component";
import { PreFilterComponent } from "./search/search-form/pre-filter/pre-filter.component";
import { ChipListComponent } from "./search/search-form/chip-list/chip-list.component";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { ListItemComponent } from "./search/list-item/list-item.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { HttpClientModule } from "@angular/common/http";
import { AnnotationComponent } from "./document-view/annotation/annotation.component";
import { BookmarkDialogComponent } from "./dialogs/bookmark-dialog/bookmark-dialog.component";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSliderModule } from "@angular/material/slider";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatMenuModule } from "@angular/material/menu";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatListModule } from "@angular/material/list";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatCardModule } from "@angular/material/card";
import { MatStepperModule } from "@angular/material/stepper";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatTabsModule } from "@angular/material/tabs";
import { MatChipsModule } from "@angular/material/chips";
import { MatInputModule } from "@angular/material/input";
import { MatDialogModule } from '@angular/material/dialog';
import { ConverterModule } from "./document-view/converter-submodule/converter.module";

@NgModule({
  declarations: [
    AppComponent,
    SearchFormComponent,
    DocumentViewComponent,
    AnnotationComponent,
    SearchComponent,
    ListItemComponent,
    HeaderComponent,
    FooterComponent,
    FullTextSearchComponent,
    FieldSpecificSearchComponent,
    AggregationsComponent,
    DateRangeComponent,
    PreFilterComponent,
    ChipListComponent,
    BookmarkDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatChipsModule,
    MatInputModule,
    MatDialogModule,
    ConverterModule,
  ],
  exports: [
    BookmarkDialogComponent,
    // andere Komponenten-Exporte
  ],
  providers: [
    // { provide: APP_BASE_HREF, useValue: '/wp-content/themes/owntheme2/datenbank/' }

    provideAnimationsAsync(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
