import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { QueryStateService } from 'src/app/services/query-state.service';
import { SEARCH_CONFIG } from 'src/search-config';
import { QueryService } from 'src/app/services/query.service';

@Component({
  selector: 'app-aggregations',
  templateUrl: './aggregations.component.html',
  styleUrls: ['./aggregations.component.scss']
})
export class AggregationsComponent implements OnInit {
  aggregations: any;
  sortOption: string = "count";

  expandedFacets = new Map<string, boolean>();
  readonly INITIAL_FACETS_COUNT = 3;

  constructor(
    public queryStateService: QueryStateService,
    private queryService: QueryService
  ) { }

  ngOnInit() {
    this.queryService.response$.subscribe(data => {
      this.aggregations = data?.aggregations;
    });


  }

  getAggregationKeys(): string[] {
    // Filter the aggs based on the show property
    const visibleAggs = SEARCH_CONFIG.aggs.filter((agg) => agg.show);

    // Map to get the ordered keys
    const orderedKeys = visibleAggs.map((agg) => agg.facetField);

    // Get the keys from the actual aggregations object
    const aggregationKeys = Object.keys(this.aggregations);

    // Return only the keys that are both in orderedKeys and aggregationKeys
    return orderedKeys.filter((key) => aggregationKeys.includes(key));
  }

  // Funktion, um die Konfiguration basierend auf aggKey zu finden
  getAggConfig(aggKey: string) {
    const config = SEARCH_CONFIG.aggs.find(agg => agg.facetField === aggKey);
    return config || { facetLabel: aggKey, facetIcon: 'default_icon' };
  }

  activateFacet(key: string, aggKey: string) {
    const label = this.getAggConfig(aggKey).facetLabel;
    this.queryStateService.addAggregation(aggKey, key, label);
  }

  deactivateFacet(key: string, aggKey: string) {
    this.queryStateService.removeAggregation(aggKey, key);
  }

  onCheckboxChange(event: any, facetKey: string, aggKey: string): void {
    const label = this.getAggConfig(aggKey).facetLabel;
    if (event.checked) {
      this.queryStateService.addAggregation(aggKey, facetKey, label);
    } else {
      this.queryStateService.removeAggregation(aggKey, facetKey);
    }
  }

  handleFacetClick(key: string, aggKey: string): void {
    const label = this.getAggConfig(aggKey).facetLabel;
    this.queryStateService.addAggregation(aggKey, key, label);
  }

  isTermSelected(aggKey: string, term: string): boolean {
    return this.queryStateService.isAggregationSelected(aggKey, term);
  }



  getSortedFacets(aggKey: string): any[] {
    if (!this.aggregations || !this.aggregations[aggKey]?.buckets) {
      return [];
    }
    const facets = [...this.aggregations[aggKey].buckets];

    // Erst sortieren
    if (this.sortOption === "alphabetical") {
      facets.sort((a: any, b: any) => a.key.localeCompare(b.key));
    } else if (this.sortOption === "count") {
      facets.sort((a: any, b: any) => b.doc_count - a.doc_count);
    }

    // Dann kürzen wenn nicht expandiert
    if (!this.expandedFacets.get(aggKey)) {
      return facets.slice(0, this.INITIAL_FACETS_COUNT);
    }

    // Sonst vollständige Liste zurückgeben
    return facets;
  }


  toggleFacetExpansion(aggKey: string) {
    this.expandedFacets.set(aggKey, !this.expandedFacets.get(aggKey));
  }

  getFacetCount(aggKey: string): number {
    return this.aggregations[aggKey]?.buckets?.length || 0;
  }
}
